.invoiceReceipt-container {
  background: white;
  position: absolute;
  z-index: 1;
  top: 100%;
  width: 40rem;
}

.invoice-header {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  height: 3rem;
}

.invoice-main-heading {
  width: 80%;
}

.invoice-closebtn {
  width: 50%;
}

.invoiceDetails {
  border: 1px solid black;
}

.invoiceList {
  list-style-type: none;
}

.invoice-firmDetails {
  border: 1px solid black;
}

.invoice-buyerInfo,
.invoice-sellerInfo {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
}

.invoice-buyerInfo {
  margin-top: 5rem;
}