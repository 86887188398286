.salesreport-main {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  background-color: white;

}

.salesreport-main {
  /* border: 2px solid #015998; */
  width: 80vw;
}

.salesreport-header {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #015998;
  color: white;
  font-size: small;
  font-style: normal;
  height: 30px;
  border-radius: 10px;
  font-family: Arial, Helvetica, sans-serif;
  margin-left: 5%;
  margin-right: 5%;
}


.table-customer-salesreport {
  /* height: 580px; */
  top: 20px;
  margin-left: 4%;
  overflow: auto;
}


.table-customer-heading-salesreport>th {
  position: sticky;
  top: 0;
  font-size: small;
  text-align: left;
}


.table-top-salesreport {
  display: flex;
  justify-content: space-between;

}

.show-name-salesreport {
  display: flex;
  align-items: center;
  margin-left: 5%;
}

.show-name-salesreport>input {
  margin-left: 10px;
}

.search-name-salesreport {
  margin-right: 5%;
}

.search-name-salesreport>input {
  padding: 5px;
  border: 1px solid #ABA4A4;
  border-radius: 4px;
  width: 200px;
  margin-top: 17px;
}

.data-table-salesreport>td>p {
  text-align: left;
  overflow: auto;
}




#customers-salesreport {
  border-collapse: collapse;
  width: 100%;
  margin-top: 1rem;


}

#customers-salesreport td,
#customers-salesreport th {

  padding: 4px;


}

.first-salesreport {
  background-color: #f2f2f2;
  border: none;
  width: 50px;
  height: 20px;
  border: 0px solid;
  text-align: center;
}

.second-salesreport {
  background-color: #ffffff;
  border: none;
  width: 50px;
  height: 20px;
  border: 0px solid;
  text-align: center;
}


#customers-salesreport tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers-salesreport tr:hover {
  background-color: #d9d9d9;
}

#customers-salesreport th {
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: #015998;
  color: white;
  text-align: center;
}

#customers-salesreport tr>td {
  cursor: pointer;
  padding: 3px 15px 3px 15px;
}

.span-width-sales-report-sales-report {
  display: block;
  white-space: nowrap;
}

.span-width-sales-report {
  padding: 5px 10px 5px 10px;
}

.csv-salesreport,
.pdf-salesreport,
.copy-salesreport {
  height: 50px;
  width: 20px;
  margin: 10px;
}

.logo-color-salesreport {
  background-color: #015998;
  padding: 10px;
  border-radius: 100px;
  color: white;
  font-size: xx-large;
  margin-top: 50%;
}

button {
  margin: 3px;
  cursor: pointer;
}

.sales-report-buttons-salesreport {
  margin: 5px;
}

.add-salesreport {
  background-color: white;
  border: 1px solid black;
  border-radius: 6px;
  text-decoration: none;
  padding: 5px;
  margin: 5px;
  font-size: x-small;

}

.update-salesreport {
  background-color: #015998;
  border-radius: 6px;
  border: #015998;
  padding: 5px;
  color: white;
  text-decoration: none;
  margin: 5px;
  font-size: x-small;
}

.delete-salesreport {
  background-color: #F91607;
  border-radius: 6px;
  padding: 5px;
  text-decoration: none;
  color: white;
  margin: 5px;
  font-size: x-small;
}

#appadd {
  white-space: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
}

.action-button-sales-table {
  display: flex;
  justify-content: center;
}

.action-button-sales-table>button {

  border-radius: 5px;
  border: #015998;
  width: 50px;
  padding: 2px;
  border: 2px solid black
}

.view-sale-report {
  background: #015998;
  color: white;
  border: none;
}

.edit-sale-report {
  background-color: white;
  color: black;
  border: rgb(85, 26, 26);
}

.del-sale-report {
  background-color: #F91607;
  color: white;
  align-items: center;
  display: flex;
  justify-content: center;
}



/* FOR MOBILE PPHONE SCREEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEN */

@media screen and (max-width: 854px) {
  .main-salesreport-file {
    margin: 5%;
    width: 300px;
  }

  .salesreport-main {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    background-color: white;
    width: 100%;
  }

  .salesreport-header {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #015998;
    color: white;
    font-size: small;
    height: 30px;
    width: 300px;
    font-family: Arial, Helvetica, sans-serif;
    margin-left: 0;
    margin-right: 0;
  }


  .table-customer-salesreport {
    height: 280px;
    margin-left: 0px;
    margin-right: 0px;
    overflow: auto;
    margin-top: 10px;
  }


  .table-customer-heading-salesreport>th {
    position: sticky;
    top: 0;
    font-size: small;
  }


  .table-top-salesreport {
    display: flex;
    flex-direction: column;
  }

  .show-name-salesreport {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 5%;
  }

  .show-name-salesreport>input {
    margin-left: 10px;
  }

  .search-name-salesreport {
    margin-right: 5%;
  }

  .search-name-salesreport>input {
    padding: 10px;
    border: 1px solid #ABA4A4;
    border-radius: 4px;
    width: 278px;
  }

  .data-table-salesreport>td>p {
    text-align: left;
    overflow: auto;
  }




  #customers-salesreport {
    border-collapse: collapse;
    width: 100%;


  }

  #customers-salesreport td,
  #customers-salesreport th {
    padding: 8px;

  }
  .first-salesreport {
    background-color: #f2f2f2;
    border: none;
    width: 90px;
    height: 20px;
    border: 0px solid;
    text-align: center;
  }

  .second-salesreport {
    background-color: #ffffff;
    border: none;
    width: 90px;
    height: 20px;
    border: 0px solid;
    text-align: center;
  }


  #customers-salesreport tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  #customers-salesreport tr:hover {
    background-color: #d9d9d9;
  }

  #customers-salesreport th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: #015998;
    color: white;

  }

  #customers-salesreport tr>td {
    text-align: center;
    cursor: pointer;

  }

  .csv-salesreport,
  .pdf-salesreport,
  .copy-salesreport {
    height: 15px;
    width: 15px;
    margin: 15px;
  }

  .logo-color-salesreport {
    background-color: #015998;
    padding: 10px;
    border-radius: 100px;
    color: white;
    font-size: xx-large;
    margin-top: 50%;
  }

  button {
    margin: 3px;
    cursor: pointer;
  }

  .sales-report-buttons-salesreport {
    margin: 5px;
  }

  .add-salesreport {
    background-color: white;
    border: 1px solid black;
    border-radius: 6px;
    text-decoration: none;
    padding: 5px;
    margin: 5px;

  }

  .update-salesreport {
    background-color: #015998;
    border-radius: 6px;
    border: #015998;
    padding: 5px;
    color: white;
    text-decoration: none;
    margin: 5px;
  }

  .delete-salesreport {
    background-color: #F91607;
    border-radius: 6px;
    padding: 5px;
    text-decoration: none;
    color: white;
    margin: 5px;
  }

  .action-button-sales-table {
    display: flex;
    justify-content: center;
  }

  .action-button-sales-table>button {
    background: #015998;
    border-radius: 0.5rem;
    border: #015998;
    width: 50px;
    color: white;

  }
}