.Purchaselist-main {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  background-color: white;
  width: 80.4vw;
}

.Purchaselist--header {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #015998;
  color: white;
  font-size: small;
  font-style: normal;
  border-radius: 10px;
  font-family: Arial, Helvetica, sans-serif;
  margin-left: 5%;
  margin-right: 2%;
}

.table-customer--Purchaselist {
  height: 580px;
  top: 20px;
  margin-left: 4%;
  overflow: auto;
}

.table-customer-heading-Purchaselist>th {
  position: sticky;
  top: 0;
  font-size: small;
}

.table-top-Purchaselist {
  display: flex;
  justify-content: space-between;
}

.show-name-Purchaselist {
  display: flex;
  align-items: center;
  margin-left: 5%;
}

.show-name-Purchaselist>input {
  margin-left: 10px;
}

.search-name-Purchaselist {
  margin-right: 5%;
}

.search-name-Purchaselist>input {
  padding: 5px;
  border: 1px solid #aba4a4;
  border-radius: 4px;
  width: 200px;
  margin-top: 16px;
}

.data-table-Purchaselist>td>p {
  overflow: auto;
}

#customers-Purchaselist {
  border-collapse: collapse;
  width: 100%;
}

#customers-Purchaselist td,
#customers-Purchaselist th {
  padding: 4px;
}

.first-Purchaselist {
  background-color: #f2f2f2;

  border: none;
  width: 50px;
  height: 20px;
  border: 0px solid;
  text-align: center;
}

.second-Purchaselist {
  background-color: #ffffff;
  border: none;
  width: 50px;
  height: 20px;
  border: 0px solid;
  text-align: center;
}

#customers-Purchaselist tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers-Purchaselist tr:hover {
  background-color: #d9d9d9;
}

#customers-Purchaselist th {
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: #015998;
  color: white;
  text-align: center;
}

#customers-Purchaselist tr>td {
  cursor: pointer;
  justify-content: center;
}

.span-width {
  display: block;
  white-space: nowrap;
}

.csv-Purchaselist,
.pdf-Purchaselist,
.copy-Purchaselist {
  height: 50px;
  width: 20px;
  margin: 10px;
}

.logo-color-Purchaselist {
  background-color: #015998;
  padding: 10px;
  border-radius: 100px;
  color: white;
  font-size: xx-large;
  margin-top: 50%;
}

button {
  margin: 3px;
  cursor: pointer;
}

.Purchase-list-buttons-Purchaselist {
  margin: 5px;
}

.add-Purchaselist {
  background-color: white;
  border: 1px solid black;
  border-radius: 6px;
  text-decoration: none;
  padding: 5px;
  margin: 5px;
  font-size: x-small;
}

.update-Purchaselist {
  background-color: #015998;
  border-radius: 6px;
  border: #015998;
  padding: 5px;
  color: white;
  text-decoration: none;
  margin: 5px;
  font-size: x-small;
}

.delete-Purchaselist {
  background-color: #f91607;
  border-radius: 6px;
  padding: 5px;
  text-decoration: none;
  color: white;
  margin: 5px;
  font-size: x-small;
}

#appadd {
  white-space: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
}

.action-button-Purchase-table {
  display: flex;
  justify-content: center;
}

.action-button-Purchase-table>button {
  border-radius: 5px;
  border: #015998;
  width: 50px;
  padding: 2px;
  border: 2px solid black;
}

.view-sale-list {
  background: #015998;
  color: white;
  border: none;
}

.edit-sale-list {
  background-color: white;
  color: black;
  border: rgb(85, 26, 26);
}

.del-sale-list {
  background-color: #f91607;
  color: white;
  align-items: center;
  display: flex;
  justify-content: center;
}


@media screen and (max-width: 854px) {
  .main-Purchaselist-file {
    margin: 5%;
    width: 300px;
  }

  .Purchaselist-main {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
    background-color: white;
  }

  .Purchaselist--header {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #015998;
    color: white;
    font-size: small;
    height: 30px;
    width: 300px;
    font-family: Arial, Helvetica, sans-serif;
    margin-left: 0;
    margin-right: 0;
  }


  .table-customer-heading-Purchaselist>th {
    position: sticky;
    top: 0;
    font-size: small;
  }

  .table-top-Purchaselist {
    display: flex;
    flex-direction: column;
  }

  .show-name-Purchaselist {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 5%;
  }

  .show-name-Purchaselist>input {
    margin-left: 10px;
  }


  .search-name-Purchaselist {
    margin-right: 5%;
  }

  .search-name-Purchaselist>input {
    padding: 5px;
    border: 1px solid #aba4a4;
    border-radius: 4px;
    width: 100%;
    margin-left: 1.5px;
  }

  .data-table-Purchaselist>td>p {
    text-align: left;
    overflow: auto;
  }

  #customers-Purchaselist {
    border-collapse: collapse;
    width: 100%;
    margin-top: 10px;
  }

  #customers-Purchaselist td,
  #customers-Purchaselist th {
    padding: 8px;
  }

  .first-Purchaselist {
    background-color: #f2f2f2;
    border: none;
    width: 90px;
    height: 20px;
    border: 0px solid;
    text-align: center;
  }

  .second-Purchaselist {
    background-color: #ffffff;
    border: none;
    width: 90px;
    height: 20px;
    border: 0px solid;
    text-align: center;
  }

  #customers-Purchaselist tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  #customers-Purchaselist tr:hover {
    background-color: #d9d9d9;
  }

  #customers-Purchaselist th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: #015998;
    color: white;
  }

  #customers-Purchaselist tr>td {
    text-align: center;
    cursor: pointer;
  }

  .csv-Purchaselist,
  .pdf-Purchaselist,
  .copy-Purchaselist {
    height: 15px;
    width: 15px;
    margin: 15px;
  }

  .logo-color-Purchaselist {
    background-color: #015998;
    padding: 10px;
    border-radius: 100px;
    color: white;
    font-size: xx-large;
    margin-top: 50%;
  }

  button {
    margin: 3px;
    cursor: pointer;
  }

  .Purchase-list-buttons-Purchaselist {
    margin: 5px;
  }

  .add-Purchaselist {
    background-color: white;
    border: 1px solid black;
    border-radius: 6px;
    text-decoration: none;
    padding: 5px;
    margin: 5px;
  }

  .update-Purchaselist {
    background-color: #015998;
    border-radius: 6px;
    border: #015998;
    padding: 5px;
    color: white;
    text-decoration: none;
    margin: 5px;
  }

  .delete-Purchaselist {
    background-color: #f91607;
    border-radius: 6px;
    padding: 5px;
    text-decoration: none;
    color: white;
    margin: 5px;
  }

  .action-button-Purchase-table {
    display: flex;
    justify-content: center;
  }

  .action-button-Purchase-table>button {
    background: #015998;
    border-radius: 0.5rem;
    border: #015998;
    width: 50px;
    color: white;
  }
}