.stocklist-main {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  background-color: white;
  width: 80%;

}

.stocklist-main {
  /* border: 2px solid black; */
  width: 80vw;
}

.stocklist-header {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #015998;
  color: white;
  font-size: small;
  font-style: normal;
  height: 30px;
  border-radius: 10px;
  font-family: Arial, Helvetica, sans-serif;
  margin-left: 5%;
  margin-right: 5%;
}


.table-customer-stocklist {
  height: 580px;
  top: 20px;
  margin-left: 4%;
  overflow: auto;
  /* width: 100%; */
}


.table-customer-heading-stocklist>th {
  position: sticky;
  top: 0;
  font-size: small;
  text-align: center;
}


.table-top-stocklist {
  display: flex;
  justify-content: space-between;

}

.show-name-stocklist {
  display: flex;
  align-items: center;
  margin-left: 5%;
}

.show-name-stocklist>input {
  margin-left: 10px;
}

.search-name-stocklist {
  margin-right: 5%;
}

.search-name-stocklist>input {
  padding: 5px;
  border: 1px solid #ABA4A4;
  border-radius: 4px;
  width: 200px;
  margin-top: 17px;
}

.data-table-stocklist>td>p {
  text-align: left;
  overflow: auto;
}




#customers-stocklist {
  border-collapse: collapse;
  width: 100%;
  margin-top: 1rem;


}

#customers-stocklist td,
#customers-stocklist th {

  padding: 8px;


}

.first-stocklist {
  background-color: #f2f2f2;
  border: none;
  width: 50px;
  height: 20px;
  border: 0px solid;
  text-align: center;
}

.second-stocklist {
  background-color: #ffffff;
  border: none;
  width: 50px;
  height: 20px;
  border: 0px solid;
  text-align: center;
}


#customers-stocklist tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers-stocklist tr:hover {
  background-color: #d9d9d9;
}

#customers-stocklist th {
  padding-top: 12px;
  padding-bottom: 12px;
  /* background-color: #015998; */
  color: white;

}

#customers-stocklist tr>td {
  cursor: pointer;
}

.span--width {
  display: block;
  white-space: nowrap;
}

.csv-stocklist,
.pdf-stocklist,
.copy-stocklist {
  height: 50px;
  width: 20px;
  margin: 10px;
}

.logo-color-stocklist {
  background-color: #015998;
  padding: 10px;
  border-radius: 100px;
  color: white;
  font-size: xx-large;
  margin-top: 50%;
}

button {
  margin: 3px;
  cursor: pointer;
}

.stock-list-buttons-stocklist {
  margin: 5px;
}

.add-stocklist {
  background-color: white;
  border: 1px solid black;
  border-radius: 6px;
  text-decoration: none;
  padding: 5px;
  margin: 5px;
  font-size: x-small;

}

.update-stocklist {
  background-color: #015998;
  border-radius: 6px;
  border: #015998;
  padding: 5px;
  color: white;
  text-decoration: none;
  margin: 5px;
  font-size: x-small;
}

.delete-stocklist {
  background-color: #F91607;
  border-radius: 6px;
  padding: 5px;
  text-decoration: none;
  color: white;
  margin: 5px;
  font-size: x-small;
}

#appadd {
  white-space: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
}

.action-button-sales-table {
  display: flex;
  justify-content: center;
}

.action-button-sales-table>button {

  border-radius: 5px;
  border: #015998;
  width: 50px;
  padding: 2px;
  border: 2px solid black
}

.view-sale-report {
  background: #015998;
  color: white;
  border: none;
}

.edit-sale-report {
  background-color: white;
  color: black;
  border: rgb(85, 26, 26);
}

.del-sale-report {
  background-color: #F91607;
  color: white;
  align-items: center;
  display: flex;
  justify-content: center;
}



/* FOR MOBILE PPHONE SCREEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEN */

@media screen and (max-width: 854px) {
  .main-stocklist-file {
    margin: 5%;
    width: 300px;
  }

  .stocklist-main {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    background-color: white;
    width: 100%;
  }

  .stocklist-header {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #015998;
    color: white;
    font-size: small;
    height: 30px;
    width: 300px;
    font-family: Arial, Helvetica, sans-serif;
    margin-left: 0;
    margin-right: 0;
  }


  /* .table-customer-stocklist {
    height: 280px;
    margin-left: 0px;
    margin-right: 0px;
    overflow: auto;
    margin-top: 10px;
  } */


  .table-customer-heading-stocklist>th {
    position: sticky;
    top: 0;
    font-size: small;
  }


  .table-top-stocklist {
    display: flex;
    flex-direction: column;
  }

  .show-name-stocklist {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 5%;
  }

  .show-name-stocklist>input {
    margin-left: 10px;
  }

  .search-name-stocklist {
    margin-right: 5%;
  }

  .search-name-stocklist>input {
    padding: 10px;
    border: 1px solid #ABA4A4;
    border-radius: 4px;
    width: 278px;
  }

  .data-table-stocklist>td>p {
    text-align: left;
    overflow: auto;
  }




  #customers-stocklist {
    border-collapse: collapse;
    width: 100%;


  }

  #customers-stocklist td,
  #customers-stocklist th {
    padding: 8px;

  }

  .first-stocklist {
    background-color: #f2f2f2;
    border: none;
    width: 90px;
    height: 20px;
    border: 0px solid;
    text-align: center;
  }

  .second-stocklist {
    background-color: #ffffff;
    border: none;
    width: 90px;
    height: 20px;
    border: 0px solid;
    text-align: center;
  }


  #customers-stocklist tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  #customers-stocklist tr:hover {
    background-color: #d9d9d9;
  }

  #customers-stocklist th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: #015998;
    color: white;

  }

  #customers-stocklist tr>td {
    text-align: center;
    cursor: pointer;

  }

  .csv-stocklist,
  .pdf-stocklist,
  .copy-stocklist {
    height: 15px;
    width: 15px;
    margin: 15px;
  }

  .logo-color-stocklist {
    background-color: #015998;
    padding: 10px;
    border-radius: 100px;
    color: white;
    font-size: xx-large;
    margin-top: 50%;
  }

  button {
    margin: 3px;
    cursor: pointer;
  }

  .stock-list-buttons-stocklist {
    margin: 5px;
  }

  .add-stocklist {
    background-color: white;
    border: 1px solid black;
    border-radius: 6px;
    text-decoration: none;
    padding: 5px;
    margin: 5px;

  }

  .update-stocklist {
    background-color: #015998;
    border-radius: 6px;
    border: #015998;
    padding: 5px;
    color: white;
    text-decoration: none;
    margin: 5px;
  }

  .delete-stocklist {
    background-color: #F91607;
    border-radius: 6px;
    padding: 5px;
    text-decoration: none;
    color: white;
    margin: 5px;
  }

  .action-button-sales-table {
    display: flex;
    justify-content: center;
  }

  .action-button-sales-table>button {
    background: #015998;
    border-radius: 0.5rem;
    border: #015998;
    width: 50px;
    color: white;

  }


}

@media screen and (min-width : 855px) and (max-width: 1236px) {
  .stocklist-main {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    background-color: white;
  }

  .stocklist-header {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #015998;
    color: white;
    font-size: small;
    height: 50px;
    width: 100%;
    font-family: Arial, Helvetica, sans-serif;
    margin-left: 0;
    margin-right: 0;
  }

/* 
  .table-customer-stocklist {
    height: 580px;
    top: 20px;
    margin-left: 5%;
    margin-right: 5%;
    overflow: auto;
  } */


  .table-customer-heading-stocklist>th {
    position: sticky;
    top: 0;
    font-size: small;
  }


  .table-top-stocklist {
    display: flex;
    flex-direction: column;

  }

  .show-name-stocklist {
    display: flex;
    align-items: center;
    margin-left: 5%;
  }

  .show-name-stocklist>input {
    margin-left: 10px;
  }

  .search-name-stocklist {
    margin-bottom: 10px;
    margin-left: 5%;
  }

  .search-name-stocklist>input {
    border: 1px solid #ABA4A4;
    border-radius: 4px;
    width: 300px;
  }

  .data-table-stocklist>td>p {
    text-align: left;
    overflow: auto;
  }




  #customers-stocklist {
    border-collapse: collapse;
    width: 100%;



  }

  #customers-stocklist td,
  #customers-stocklist th {
    padding: 8px;

  }

  .first-stocklist {
    background-color: #f2f2f2;
    border: none;
    width: 35px;
    height: 20px;
    border: 0px solid;
    text-align: center;
  }

  .second-stocklist {
    background-color: #ffffff;
    border: none;
    width: 20px;
    height: 20px;
    border: 0px solid;
    text-align: center;
  }


  #customers-stocklist tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  #customers-stocklist tr:hover {
    background-color: #d9d9d9;
  }


  #customers-stocklist th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: #015998;
    color: white;

  }

  #customers-stocklist tr>td {
    text-align: center;
    cursor: pointer;

  }

  .csv-stocklist,
  .pdf-stocklist,
  .copy-stocklist {
    height: 50px;
    width: 20px;
    margin: 10px;
  }

  .logo-color-stocklist {
    background-color: #015998;
    padding: 10px;
    border-radius: 100px;
    color: white;
    font-size: xx-large;
    margin-top: 50%;
  }

  button {
    margin: 3px;
    cursor: pointer;
  }

  .stock-list-buttons-stocklist {
    margin: .5px;
    width: 10px;
  }

  .add-stocklist {
    background-color: white;
    border: 1px solid black;
    border-radius: 6px;
    text-decoration: none;
    padding: 1px;
    margin: 2px;
    font-size: xx-small;
    font-weight: 100;
  }

  .update-stocklist {
    background-color: #015998;
    border-radius: 6px;
    border: #015998;
    padding: 1px;
    color: white;
    text-decoration: none;
    margin: 2px;
    font-size: xx-small;
    font-weight: 100;

  }

  .delete-stocklist {
    background-color: #F91607;
    border-radius: 6px;
    padding: 1px;
    text-decoration: none;
    color: white;
    margin: 2px;
    font-size: xx-small;
    font-weight: 100;
  }


}

@media screen and (min-width: 2000px) {
  .sales-main {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    background-color: white;
  }

  .sales-header {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #015998;
    color: white;
    font-size: xx-large;
    height: 75px;
    font-family: Arial, Helvetica, sans-serif;
  }

  .table-customer {
    height: 580px;
    top: 20px;
    margin-left: 5%;
    margin-right: 5%;
    overflow: auto;
  }


  .table-customer-heading>th {
    position: sticky;
    top: 0;
  }


  .table-top {
    display: flex;
    justify-content: space-between;
  }

  .show-name {
    display: flex;
    align-items: center;
    margin-left: 5%;
  }

  .show-name>input {
    margin-left: 10px;
  }

  .search-name {
    margin-right: 5%;
  }

  .search-name>input {
    padding: 10px;
    border: 1px solid #ABA4A4;
    border-radius: 4px;
    width: 300px;
  }

  .data-table>td>p {
    text-align: left;
    overflow: auto;
  }




  #customers {
    border-collapse: collapse;
    width: 100%;


  }

  #customers td,
  #customers th {
    padding: 8px;

  }

  .first {
    background-color: #E6E6E6;
    border: none;
    width: 90px;
    height: 20px;
    border: 0px solid;
    text-align: center;
  }

  .second {
    background-color: #ffffff;
    border: none;
    width: 90px;
    height: 20px;
    border: 0px solid;
    text-align: center;
  }


  #customers tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  #customers tr:hover {
    background-color: #d9d9d9;
  }

  #customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: #015998;
    color: white;

  }

  #customers tr>td {
    text-align: center;
    cursor: pointer;

  }

  .csv,
  .pdf,
  .copy {
    height: 50px;
    width: 20px;
    margin: 10px;
  }

  .logo-color {
    background-color: #015998;
    padding: 10px;
    border-radius: 100px;
    color: white;
    font-size: xx-large;
    margin-top: 50%;
  }

  button {
    margin: 3px;
    cursor: pointer;
  }

  .stock-list-buttons {
    margin: 5px;
  }

  .add {
    background-color: white;
    border: 1px solid black;
    border-radius: 6px;
    text-decoration: none;
    padding: 5px;
    margin: 5px;

  }

  .update {
    background-color: #015998;
    border-radius: 6px;
    border: #015998;
    padding: 5px;
    color: white;
    text-decoration: none;
    margin: 5px;
  }

  .delete {
    background-color: #F91607;
    border-radius: 6px;
    padding: 5px;
    text-decoration: none;
    color: white;
    margin: 5px;
  }

  .main-sales-file {

    margin: 5%;
  }


}