


.main-div-purchase-entry-main {
  background-color: blue;
}

.quantity-box-top-top {
  height: 15rem;
  overflow: scroll;
  margin-top: 20px;
}


.ledger-view-entry {
  background-color: rgb(5, 68, 122);
  color: white;
  width: 68.7rem;
  font-size: x-large;
  height: 2.5rem;
  border-radius: 5px;
  margin-top: 10px;
  align-items: center;
  display: flex;
  justify-content: center;
  font-weight: bold;
  padding-bottom: 6px;
}

.center--values {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 1rem;
  font-weight: bold;
}

.inner-div {
  margin-left: 15rem;
  margin-top: -7rem;
  align-items: center;
  display: grid;
  justify-content: right;
}


#customers-purchase-entry-ledger {
  border-collapse: collapse;
  width: 100%;
  overflow: auto;
}

#customers-purchase-entry-ledger td,
#customers-purchase-entry-ledger th {
  padding: 3.3px;

}

#customers-purchase-entry-ledger input {
  width: 58px;
  height: 30px;
  background-color: #36323237;
  border: 0px solid;
  text-align: center;
}

#customers-purchase-entry-ledger tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers-purchase-entry-ledger tr:hover {
  background-color: #d9d9d9;
}

#customers-purchase-entry-ledger th {
  padding-top: 1px;
  padding-bottom: 1px;
  text-align: center;
  background-color: #015998;
  color: white;

}

#customers-purchase-entry-ledger tr>td {
  text-align: center;
  cursor: pointer;

}


/* new css added */

#parent-div{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

#inputbox{
   height: 30px;
   margin-left: 10px;
   width: 200px;
}

#displayflexrow{
  /* display: flex; */
  flex-direction: row;
  /* align-items: center; */
}

.inner--div{
}
