.form-box-firmGen {
  /* border: 1px solid #015998; */
  padding-left: 5%;
  padding-right: 5%;
  background-color: white;
  width: 80vw;
  /* margin-top:2% */
}

.notificationImage {
  background-color: whitesmoke;
  width: 2rem;
  height: 2rem;
}

.inputFeild {
  height: 2rem;
}


@media only screen and (max-width: 850px) {
  .c-form-firm-gen-1 {
    height: fit-content;
    width: fit-content;
    background-color: white;
    margin-left: 7rem;
  }

  .heading-firm-gen-1 {
    background-color: #015998;
    border-radius: 5px;
    width: 100%;
    height: 30px;

    margin-bottom: 1rem;
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-size: 20px;
    text-align: center;
    color: #fdf9f9;
  }

  #c-form-required-firmname {
    margin-left: 1.5rem;
  }

  .firmnameinut {
    margin-left: 2.5rem;
    width: 70%;
  }

  .two-main {
    display: grid;
  }

  .c-form-input-firm {
    width: 70%;
  }

  .two-columns-three {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .radiolabel-1 {
    margin-left: 1rem;
    color: black;
  }

  .inputradio-button {
    height: 10px;
    width: 10px;
  }

  .radiobutton-1 {
    display: flex;
    margin-left: 1rem;
    margin-bottom: 1rem;
    color: black;
  }

  .radiobutton-1>div>label {
    font-family: Arial, Helvetica, sans-serif;
    font-size: small;
    color: black;

  }

  #button-sumbit-firm {
    background-color: #015998;
    margin-left: 5rem;
    color: white;
    border-radius: 10px;
  }

  .c-form-select-firm-state {
    width: 60%;
    height: 1.9rem;
    border: 1px solid black;
    border-radius: 6px;
  }

  .banklabel-01-bank {
    margin-left: 3.8rem;
    color: rgb(22, 172, 231);
    font-size: larger;
  }
}

@media screen and (min-width: 850px) and (max-width: 1200px) {
  .c-form-firm-gen-1 {
    height: fit-content;
    width: fit-content;
    background-color: white;
  }

  .heading-firm-gen-1 {
    background-color: #015998;
    border-radius: 5px;
    width: 94.5%;
    margin-bottom: 3rem;
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-size: 20px;
    text-align: center;
    color: #fdf9f9;
  }

  .firmnameinut {
    width: 95%;
  }

  .two-main {
    display: grid;
  }

  .c-form-input-firm {
    width: 90%;
    margin-right: 5rem;
  }

  .c-form-select-firm-state {
    width: 93%;
    margin-right: 3rem;
    height: 1.9rem;
    border: 1px solid black;
    border-radius: 6px;
  }

  .two-columns-three {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .radiolabel-1 {
    margin-left: 1rem;
    color: black;

  }

  .inputradio-button {
    height: 10px;
    width: 10px;
  }

  .radiobutton-1 {
    display: flex;
    gap: 20px;
    margin-left: 1rem;
    margin-bottom: 1rem;
  }

  .radiobutton-1>div>label {
    font-family: Arial, Helvetica, sans-serif;
    color: black;

  }

  #button-sumbit-firm {
    background-color: #015998;
    border: #015998;
    color: white;
    height: 30px;
    width: 60px;
    border-radius: 10px;
  }

  .firm-gen-submit-button {
    display: flex;
    justify-content: center;
  }

  .banklabel-01-bank {
    margin-left: 1rem;
    color: rgb(0, 0, 0);
    font-size: larger;
  }
}

@media screen and (min-width: 1200px) and (max-width: 3000px) {
  .c-form-firm-gen-1 {
    height: fit-content;
    width: 100%;
    background-color: white;
  }

  .heading-firm-gen-1 {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #015998;
    color: white;
    font-size: x-large;
    font-style: normal;
    margin-bottom: 30px;
    font-weight: 700;
    height: 30px;
    border-radius: 5px;
    font-family: Arial, Helvetica, sans-serif;
  }

  .firmnameinut {
    width: 100%;
  }

  .top-row-firmname {
    width: 104 0.5%;
  }

  .two-main {
    display: grid;
  }

  .c-form-input-firm {
    width: 100%;
    margin-right: 15rem;
  }

  .two-columns-three {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .radiolabel-1 {
    margin-left: 1rem;
    color: black;
  }

  .inputradio-button {
    height: 12px;
    width: 12px;
  }

  .radiobutton-1 {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 2rem;
    color: black;

  }

  .radiobutton-1>div>label {
    font-family: Arial, Helvetica, sans-serif;
    font-size: medium;
    color: black;

  }

  #button-sumbit-firm {
    background-color: #015998;
    border: #015998;
    color: white;
    height: 30px;
    width: 120px;
    border-radius: 8px;
  }

  .firm-gen-submit-button {
    display: flex;
    justify-content: center;
  }

  .c-form-select-firm-state {
    width: 100%;
    margin-right: 12rem;
    height: 1.9rem;
    border: 1px solid black;
    border-radius: 6px;
    padding-left: 0.5rem;
  }

  .banklabel-01-bank {
    margin-left: 1rem;
    color: rgb(0, 0, 0);
    font-size: larger;
  }
}


/* I added this css */

.c-form-firm-gen-1 input[type="text"],
.c-form-firm-gen-1 input[type="email"],
.c-form-firm-gen-1 input[type="select"],
.c-form-firm-gen-1 select {
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.2);
  border: 1px solid #ccc;
  /* Optional: Add a border to make the input stand out */
  border-radius: 4px;
  /* Optional: Add rounded corners to the input */
  padding: 6px;
  /* Optional: Add some padding to the input */
  color: #000;
  /* Optional: Set text color to black */
}

/* Apply the same box-shadow to all placeholders in the form */
.c-form-firm-gen-1 ::placeholder {
  color: rgba(0, 0, 0, 0.2);
}

/* Apply bold font to labels with class "c-form-label" */
.c-form-firm-gen-1 label.c-form-label {
  font-weight: bold;
}