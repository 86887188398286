body {
  position: absolute;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5;
}

.app {
  display: flex;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.main--div {
  /* height: fit-content;
  width: 59rem;
  background-color: white;
  overflow-x: hidden;
  height: fit-content;
  background-color: white;
  overflow-x: hidden; */

  height: fit-content;
  background-color: white;
  overflow-x: hidden;

}

.div-header {
  color: white;
  background: #015998;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  margin-left: 5%;
  margin-right: 2%;
}

.first-half-sale {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.top-two-sale {
  display: grid;
  gap: 10px;
  margin-left: 5%;
}

.input-box--sale {
  width: 59vw;
  height: 200px;
  margin-top: 0px;
  border: 1px solid #015998;
  border-radius: 0px;
  background: #ffffff;
  display: flex;
  flex-wrap: wrap;
  font-weight: 700;
  font-size: large;
}

.input-box--sale>input {
  background: #ffffff;
  border-radius: 4px;
  height: 25px;
  margin-top: 5px;
  width: 200px;
}

.input-box-name--sale {
  margin-left: 3%;
  display: flex;
  flex-direction: column;
}

.input-box-date--sale {
  margin-left: 5%;
  display: flex;
  flex-direction: column;
  margin-top: 0.4rem;
  /* background-color: rgb(255, 255, 255); */
  font: white;
  cursor: pointer;
  border-radius: 7px;
}

.input-box-invoice-sale {
  margin-left: 5%;
  display: flex;
  flex-direction: column;
  margin-top: 0.4rem;
  /* background-color: rgb(255, 255, 255); */
  font: white;
  cursor: pointer;
  border-radius: 7px;
}

.regionDiv {
  width: 20%;
  margin-left: 5%;
}

.cnRef-box-invoice-sale {
  margin-left: 5%;
  display: flex;
  flex-direction: column;
  /* margin-top: 0.4rem; */
  background-color: rgb(255, 255, 255);
  font: white;
  cursor: pointer;
  border-radius: 7px;
}

.cnRef-box-invoice-sale > label {
   margin-bottom: -10px;
   color: black;
}

.cnRef-box-invoice-sale > input{
   width: 80%;
   height:1.8rem;
}


.input-box-region {
  margin-left: 2rem;
}

.input-box-date {
  margin-left: 2rem;
}

.input-box-invoice {
  margin-left: 2rem;
}

.quantity--box {
  width: 59vw;
  height: 320px;
  border: 1px solid #015998;
  border-radius: 0px;
  background: #ffffff;
}

.product {
  overflow: auto;
}

.qty-comments {
  width: fit-content;
  padding: 1rem;
  margin-top: -300px;
  margin-right: 5rem;
  height: fit-content;
  border: 5px solid #c1c4c1;
  border-radius: 10px;
  background-color: hsl(0, 0%, 100%);
}

.box>div>div>label {
  font-size: small;
  font-family: Arial, Helvetica, sans-serif;
}

.box>div>div>div>div>label {
  font-size: larger;
  font-family: Arial, Helvetica, sans-serif;
}

.label--for-second {
  align-items: center;
  display: flex;
  justify-content: left;
  gap: 1rem;
  margin-bottom: 21px;
}

.label--for-second>div {
  display: grid;
}

.label--for-second>div>input {
  height: 30px;
  width: 60px;
}

.label-for-four {
  align-items: center;
  display: flex;
  justify-content: left;
  gap: 1rem;
}

.label-for-four>div>input {
  height: 30px;
  width: 50px;
}

.label-for-four>div {
  display: grid;
}

.TAX {
  margin-top: 1.4rem;
  font-size: larger;
  font-family: Arial, Helvetica, sans-serif;
  margin-right: -1rem;
  margin-left: -1.5rem;
}

.NetRate {
  margin-left: -0.5rem;
}

#BatchNo {
  height: 30px;
  width: 90px;
}

#Expiry {
  height: 30px;
  width: 90px;
}

#MRP {
  height: 30px;
  width: 80px;
}

#HSN {
  height: 30px;
  width: 90px;
}

.qty--details {
  position: relative;
  right: 11rem;
  /* bottom: 21%; */
  height: auto;
  /* width: 127%; */
  font-size: small;
  padding: 1px;
  overflow: auto;
}

.qty-details-header>th {
  position: sticky;
  height: fit-content;
  width: fit-content;
  margin-top: 10rem;
}

.contact-box--sale>input {
  /* box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.2); */
  /* width:96%; */

  /* margin-left: 5px; */
  width: 145px;
  height:30px;
  /* background: #f5f5f5; */
  border: 1px solid #000000;
  border-radius: 4px;
  /* box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.2); */

}

.contact-box--sale {
  width: 200px;
  display: grid;
  justify-content: center;
  background: #ffffff;
  border: 1px solid #015998;
  padding-top: 0.6rem;
  padding-bottom: 1rem;
  border-color: "grey";
  box-shadow: "0px 5px 5px 0px rgba(0, 0, 0, 0.2)";
}

.contact-box--sale > label {
  margin-left:3px;
}

.bottom--half {
  display: flex;
  gap: 10px;
  margin-top: 5px;
  /* width: 70.5rem; */
  margin-left: 48px;
  height: 21rem;
}

.total--box {
  /* width: 45.4rem;
  border: 1px solid #015998;
  display: flex;
  justify-content: space-between; */

  width: 59vw;
  border: 1px solid #015998;
  border-radius: 0px;
  display: flex;
  justify-content: space-between;
  /* height: 23rem; */
}

.total--empty {
  margin-top: 12px;
  width: 600px;
  font-size: small;
  gap: 0;
  height: 240px;
  border: 1px solid #015998;
  margin-left: 10px;
  padding: 10px;
}

.save-print-view--button {
  margin-top: 15rem;
  margin-left: 9rem;
  display: flex;
  height: fit-content;
  width: fit-content;
  justify-content: center;
  align-items: center;
  gap:10px;
}

.save-button {
  cursor: pointer;
  background-color: #015998;
  color: white;
  font-size: large;
  height: 40px;
  width: 100px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.view-button {
  cursor: pointer;
  background-color: #015998;
  color: white;
  font-size: large;
  height: 40px;
  width: 100px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.print-button {
  cursor: pointer;
  background-color: #015998;
  color: white;
  font-size: large;
  height: 40px;
  width: 100px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.total-values--button {
  /* display: grid; */
  margin-right: 10px;
  margin-top:16px;
  margin-left: 10px;
}

.total-values--button>button {
  cursor: pointer;
  background-color: #015998;
  color: white;
  border-radius: 10px;
  width: 10rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom:20px;

}

.blank--box {
  position: relative;
  width: 165px;
  display: grid;
  justify-content: center;
  background: #ffffff;
  border: 1px solid #015998;
  padding-top: 0.6rem;
  padding-bottom: 1rem;
}

.blank--box>input {
  margin-left: 5px;
  width:90%;
  height: 30px;
  /* border: 1px solid #000000; */
  border-radius: 4px;
  /* box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.2); */
}

.blank-box-purchase {
  width: 163px;
  justify-content: center;
  background: #ffffff;
  border: 1px solid #015998;
  border-radius: 1px;
}

.blank-box-purchase>input {
  /* margin-left: 5px; */
  width: 150px;
  /* height: 2rem; */
  height: 30px;
  background: white;
  /* border: 5px; */
  /* border-radius: 5px; */
  border: 1px solid #000000;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.2);
  margin-left: 5px;
}


/* #customers--purchase {
  border-collapse: collapse;
  overflow: auto;
} */

/* #customers--purchase td,
#customers--purchase th {
  padding: 3.3px;

} */

/* #customers--purchase input {
  width: 58px;
  height: 20px;
  background-color: #36323237;
  border: 0px solid;
  text-align: center;
} */

/* #customers--purchase tr:nth-child(even) {
  background-color: #f2f2f2;
} */

/* #customers--purchase tr:hover {
  background-color: #d9d9d9;
} */

/* #customers--purchase th {
  padding-top: 1px;
  padding-bottom: 1px;
  padding-left: 6px;
  text-align: center;
  background-color: #015998;
  color: white;
} */

/* #customers--purchase tr>td {
  text-align: center;
  cursor: pointer;
} */

#customers {
  border-collapse: collapse;
  width: 60%;
  overflow: auto;
}

#customers td,
#customers th {
  padding: 3.3px;
}

#customers input {
  width: 58px;
  height: 20px;
  background-color: #36323237;
  border: 0px solid;
  text-align: center;
}

#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers tr:hover {
  background-color: #d9d9d9;
}

#customers th {
  padding-top: 1px;
  padding-bottom: 1px;
  text-align: center;
  background-color: #015998;
  color: white;
}

#customers tr>td {
  text-align: center;
  cursor: pointer;
}

.checkbox-td {
  display: flex;
}

.checkbox-1 {
  margin-left: -1rem;
}

.checkbox-3 {
  margin-right: -1rem;
  margin-left: -2rem;
}

.checkbox-2 {
  margin-left: -2rem;
}

.submit-firm {
  background-color: #015998;
  margin-left: 8rem;
  margin-top: 1rem;
  height: 30px;
  width: 150px;
  font-size: large;
  font-family: Arial, Helvetica, sans-serif;
  cursor: pointer;
  color: white;
}

.ProductName {
  margin-top: 4px;
  height: 23px;
  width: 100px;
  background-color: rgb(206, 202, 206);
  font: white;
  cursor: pointer;
  border-radius: 7px;
}

.FirmName_Sale {
  margin-top: 0.4rem;
  margin-left: 1rem;
  height: 28px;
  width: 100px;
  /* background-color: rgb(255, 255, 255); */
  font: white;
  cursor: pointer;
  border-radius: 7px;
}

.FirmName_Sale>input {
  /* box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.2); */
}

.Date-Invoice-Amt-Main {
  border: #ff1515;
  height: 10rem;
  width: 43rem;
  margin-left: 1rem;
  display: flex;
  align-items: grid;
  justify-content: center;
}

.Date-Invoice-Amt {
  position: absolute;
  width: 333px;
  height: 30px;
  background: #015998;
  border-radius: 10px;
}

.Date-Invoice-Amt-Main>div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4rem;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  font-size: larger;
  font-weight: bold;
}

.input-div {
  position: absolute;
  width: 333px;
  height: 30px;
  margin-top: 3rem;
  background: #d9d9d9;
  border-radius: 10px;
}

.input-div>input {
  background-color: #d9d9d9;
  width: 60px;
  border: none;
}

.product-result-label {
  background-color: #015998;
  font-family: Arial, Helvetica, sans-serif;
  color: white;
  margin: 1px;
  padding: 1px;
}

.product-result {
  margin: 1px;
  padding: 1px;
  height: 12px;
  width: 150px;
}

#arrayofobjectupdate {
  position: relative;
  width: 46px;
  height: 25px;
  margin-left: 1px;
  margin-top: -10px;
}

.none {
  display: none;
}

.customers-sales-test>tr>th {
  color: black;
}


table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 8px;
  text-align: center;
}

th {
  background-color: #f2f2f2;
  border: none;
}

td {
  border: 1px solid #ddd;
}

.saleInvoice-costDetails .saleInvoice-amountList:first-child td {
  text-align: left;
}

.saleInvoice-costDetails .saleInvoice-amountList:nth-child(2) td {
  text-align: right;
}

table th,
table td {
  text-align: center;
}


.FirmName-sale{
  color: black;
}












/* for qty details  */

.qty--details-firm-select{
  position: relative;
  right: 10rem;
  bottom: 2.6rem;
  /* bottom: 21%; */
  /* height: auto; */
  /* width: 127%; */
  /* font-size: small; */
  /* padding: 1px; */
  /* overflow: auto; */
  /* width: 30rem; */
  /* background-color: red; */
}

.table {
  display: flex;
  flex-direction: column;
  border: 1px solid #015998;
  overflow: auto;
}

.table-row {
  display: flex;
}

.table-cell {
  flex: 1;
  border: 1px solid #dddddd;
  padding: 8px;
}

.table-row.header {
  font-weight: bold;
  background-color: #015998;
  position: sticky;
  top: 0;
  z-index: 1;
}

.table-body {
  /* overflow-y: auto; */
  max-height: 200px; 
  /* Adjust the maximum height as needed */
}




/* discount click pop box border color */

.qty--comments{
  border: 5px solid #015998;
}

.firmContainer{
  width: 32rem;
}

.firmListHeadingDiv{
  width: 10rem;
}

.productContainer{
  /* width: 40.8rem; */
}

.belowNavbar{
  /* width: 40.8rem; */
}


/* firm pop up css is here  */

/* s/* styles.css */

/* Firm Container */
.firmContainerSales {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Top Section */
.topSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 43rem;
  background-color: #015998;
  /* Add other necessary styles */
}



/* Overlay to cover the entire screen */
/* overlay and firmcontaniner-sales these are two div for firm select and product select center  with blur background*/
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 1000; 
  /* Ensure it's on top of other content */
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px); /* Apply the blur effect */
}


#firmContainer-sales {
  position: relative;
  z-index: 1500; /* Place it above the overlay */
  /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25); */
  border-radius: 6px;
  /* width: 44rem; */
  background: whitesmoke;
  background-color: white;
}


/* product select pop up table format  */
/* Your existing CSS */
/* Wrapper for the table */





/* Wrapper for the table */
.productsellTableWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 20rem; /* Set the fixed height */
  overflow-y: auto; /* Enable vertical scrolling */
  border: 1px solid #ccc;
   /* Border style */
}

/* Header row */
.productsellHeaderRow {
  display: flex;
  background-color: #015998; /* Change background color for the header */
  color: white; /* Change font color for the header */
  font-weight: bold; /* Optional: Bold font for header cells */
  align-items: left; 
  /* Align header text in center */
}

/* Table cells for header */
.productsellHeaderRow > div {
  flex: 1;
  padding: 8px;
  /* text-align: center;  */
  /* Align header text in center */
  /* border-right: 1px solid #ccc;  */
  /* Border between header cells */
  white-space: nowrap; /* Prevent line breaks in header */
  overflow: hidden;
  text-overflow: ellipsis; /* Show ellipsis for longer text */
  min-width: 100px; /* Set a minimum width for header cells */
}

/* Remove the border from the last header cell */
.productsellHeaderRow > div:last-child {
  border-right: none;
}

/* Table body */
.productsellTableBody {
  display: flex;
  flex-direction: column;
  overflow-y: auto; /* Enable vertical scrolling for the body */
}

/* Table row */
.productsellTableRow {
  display: flex;
  border-bottom: 1px solid #ccc; /* Border for table rows */
}

/* Table cells for body */
.productsellTableRow > div {
  flex: 1;
  padding: 8px;
  border-right: 1px solid #ccc; /* Border between cells */
  white-space: nowrap; 
  /* Prevent line breaks in body */
  overflow: hidden;
  /* text-overflow: ellipsis;  */
  /* Show ellipsis for longer text */
  min-width: 100px; /* Set a minimum width for body cells */
}

/* Remove the border from the last body cell */
.productsellTableRow > div:last-child {
  border-right: none;
}



/* Table cells for serial number in header */
.productsellThDiv.productsellSD {
  flex: 0.5; /* Adjust the width for serial number cells */
  min-width: 20px; /* Set a minimum width for serial number cells */
}

/* Table cells for serial number in body */
.productsellTdDivp.productsellSD {
  flex: 0.5; /* Adjust the width for serial number cells */
  min-width: 20px; /* Set a minimum width for serial number cells */
}


.productsellSelectButton {
  background: #015998;
  color: whitesmoke;
  border: none;
  border-radius: 3px;
  padding: 10px 1px; /* Adjust padding to fit the text */
  font-size: 12px; /* Change font size if necessary */
  font-weight: bold;
}



/* new css  */

#selectproductpopoldRow{
 display: flex;
 padding: 10px;
}

#selectproductpopoldHeader {
  font-weight: bold;
  background-color: #015998;
  color: white;
  display: flex;
}



/* product-select-table-header */

#product-select-table-header > div {
   padding-right: 50px;
   background-color: #015998;
}

/* second pop up css */

#product-select-table-data > div{
  padding-right: 40px;
}

.table-cell-second-pop-up{
   flex: 1;
   border: 1px solid #dddddd;
   padding: 8px;
   min-width: 10rem;
   white-space: normal;
   word-wrap: break-word;
 }


/* meg table body  */
/* third pop up degin */
/* -third-pop-up */



/* third m e g select pop up css  */

.table-Third-pop-up-meg-table {
  /* width: 100%;
  border-collapse: collapse;
  display: flex;
  flex-direction: column;
  border: 1px solid #015998;
  overflow: auto; */
  height: 12rem;
}

.Table-header-content {
  font-weight: bold;
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  color: white;
}


.table-cell-for-third-table {
  flex: 1;
  border: 1px solid #dddddd;
  padding: 8px;
  min-width: 10rem;
  white-space: normal;
  word-wrap: break-word;
}

#third-table-header{
  background-color: #015998;
}


#third-table-header-sno{
  min-width: 3rem;
  padding: 8px;
  border: 1px solid #dddddd;
  white-space: normal;
  word-wrap: break-word;
  background-color: #015998;
}

#third-table-header-sno-1{
  min-width: 3rem;
  padding: 8px;
  border: 1px solid #dddddd;
  white-space: normal;
  word-wrap: break-word;
}



/* product entry where user enter multiple products entry css */


/*customers-purchase-entry table */

#customers-purchase-entry {
  border-collapse: collapse;
  width: 100%;
  overflow: auto;
}

#customers-purchase-entry td,
#customers-purchase-entry th {
  padding: 3.3px;
}

#customers-purchase-entry input {
  width: 70px;
  height: 30px;
  background-color: #36323237;
  border: 0px solid;
  text-align: center;
}

#customers-purchase-entry tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers-purchase-entry tr:hover {
  background-color: #d9d9d9;
}

#customers-purchase-entry th {
  padding-top: 1px;
  padding-bottom: 1px;
  text-align: center;
  background-color: #015998;
  color: white;
  width: 4rem;
}

#customers-purchase-entry tr>td {
  text-align: center;
  cursor: pointer;
}




/* display profit and loss sell pop up css  */

.parent-container-Profit-loss-pop-up {
  /* Styles for your parent container if any */
  /* background-color: red; */
  /* border-radius: 10px;
  border: 1px solid #015998; */
}


.popup-content {
  /* background-color: #fff; */
  padding: 20px;
  max-width: 400px;
  text-align: center;
  border-radius: 10px;
  border: 5px solid #015998;
}

.popup-header {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 10px;
}

  .popup-body {
    font-size: 1em;
    line-height: 1.5;
    color: #333; /* Text color */
    margin-bottom: 20px;
  }


.popup-buttons{
    display: flex;
    justify-content: center; /* Center the buttons horizontally */
  }


.popup-buttons button {
  margin: 0 6px;
  padding: 8px 16px;
  cursor: pointer;
}


/* warning pop up there is any mandatory field is empty  */

.parentWarningPopup{
  height: 12rem;
  width: 19rem;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* background-color: #e8232d; */
  /* border: 2px solid white; */
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background: linear-gradient(to bottom, red 10%, white 28%);
}


.top {
  height: 20%;
  /* background-color: red; */
}

.bottom {
  height: 70%;
  background-color: white;
  /* display: flex; */
  justify-content: center;
  align-items: center;
}


.close-icon {
  position: absolute;
  top: 5px;
  right: 15px;
  font-size: 24px;
  color: white;
  cursor: pointer;
}

.warning-title {
  font-size: 24px;
  color: black;
  font-weight: bold;
  margin-bottom: 10px;
  display: flex;
  justify-content:center;
  align-items: center;
}

.warning-message {
  font-size: 18px;
  color: black;
  text-align: center;
}

.warning-icon {
  width: 50px; /* Adjust the icon size as needed */
  height: 50px;
  display: flex;
  justify-content:center;
  align-items: center;
  margin-left: 6.5rem;
  margin-top: -1rem;
}

