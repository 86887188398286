.table-container {
    display: flex;
    flex-direction: column;
    border: 1px solid #ddd;
    font-family: Arial, sans-serif;
    max-height: 400px;
    overflow-y: auto;
    margin-left: 4%;
  }
  
  .list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    border-bottom: 1px solid #ddd;
    
  }
  
  .list-item div {
    width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 8px;
  }
  
  .header {
    font-weight: bold;
    background-color: #015998;
    color: white;
  }
  


 
  /* for serial no fixed width */
  .list-item .sno {
    width: 44px;
  }
  
  .list-item div:not(.sno) {
    width: 122px; 
    /* Or any desired width for other columns */
  }
  
  /* to make action in the center */
  .list-item .action-header {
    /* width: 200px;  */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .list-item .action-buttons button {
    width: 33px;
    height: 25px;
    font-size: 10px;
    font-weight: bold;
    padding: 10;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  
  .list-item .action-buttons {
    width: 40px;
    display: flex;
    /* flex-wrap: wrap; */
    flex-direction: row;
    gap: 1px;
  }



  .table-container .list-item:hover:not(.header) div {
    background-color: whitesmoke;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding: 8px; */
    /* border-bottom: 2px solid #ddd; */
  }
  