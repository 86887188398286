.main-ledger-div {
  width: 80.4vw;
  background-color: white;
  padding-left: 10px;
  padding-top: 10px;
  padding-right: 10px;
}

.header-ledger-list {
  background: #015998;
  border-radius: 5px;
  width: 91.5%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: larger;
  height: 39px;
  margin-left: 4%;
  margin-top: 15px
}

/*--------------search abr-------- */
.show-name-dashboard-ledger-list {
  display: flex;
  align-items: center;
}


.input-account-ledger-list>input {
  width: 300px;
  position: relative;
  left: 10px;
  margin-top: 15px;
}

.input-region-ledger-list>input {
  margin-left: 29px;
  margin-top: 60px;
}

.table-top-Productlist {
  display: flex;
  justify-content: space-between;

}

.show-name-Productlist {
  display: flex;
  align-items: center;
  margin-left: 5%;
}

.show-name-Productlist>input {
  margin-left: 10px;
}

.search-name-Productlist {
  margin-right: 5%;
}

.search-name-Productlist>input {
  padding: 10px;
  border: 1px solid #ABA4A4;
  border-radius: 4px;
  width: 200px;
  margin-top: 16px;
}

/*-------------- table div-------- */

.third-ledger-list {
  width: 100%;
  height: 200px;
  margin-top: 50px;

}

.table-ledger--list {
  border-collapse: collapse;
  width: 100%;
  overflow: auto;

}

.table-ledger--list td,
.table-ledger--list th {
  padding: 1px;

}


.table-ledger--list tr:nth-child(even) {
  background-color: #f2f2f2;
}

.table-ledger--list tr:hover {
  background-color: #d9d9d9;
}

.table-ledger--list th {
  padding-top: 2px;
  padding-bottom: 2px;
  text-align: center;
  background-color: #015998;
  color: white;

}

.table-ledger--list tr>td {
  text-align: center;
  cursor: pointer;
  font-size: inherit;
  font-weight: 500;
}



/* --------REMARKS-------- */

.remarks-ledger-list {
  display: flex;
  align-items: center;
  margin-left: 22%;
  width: auto;
  margin-top: 40px;
}

.remarks-ledger-list-text {
  display: flex;
  align-items: center;
  border-radius: 10px;
  height: 20px;
  background-color: #015998;
  color: white;
  padding: 4px;
  font-size: large;
  font-weight: 500;
}

.remarks-ledger-list-input>input {
  width: 150%;
  margin-left: 10px;
  background: #D9D9D9;
  border: none;
  margin-right: 10px;
  border-radius: 10px;
  margin-top: 0px;
}



/* foooter */

.footer-ledger-list {
  display: flex;
  justify-content: flex-end;
  background-color: #015998;
  margin-top: 60px;
  height: 60px;
}

.footer-ledger-list-div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: white;
  gap: 30px;
  font-size: larger;
  font-weight: 500;
}

.footer-ledger-list-div>input {
  width: 70px;
  background: #D9D9D9;
  border: none;
  margin-right: 10px;
  border-radius: 10px;
  margin-top: 0px;
}

@media only screen and (max-width: 854px) {
  .main-ledger-div {
    background-color: white;
    padding-left: 1%;
    padding-top: 10px;
    padding-right: 1%;
    width: 100%;
  }

  .header-ledger-list {
    background: #015998;
    border-radius: 10px;
    width: 98%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: small;
    height: 30px;
  }

  /*-------------- ACCOUNT NUMBER-------- */
  .second-ledger-list {
    display: flex;
    justify-content: space-evenly;
    margin-top: 10px;
    font-weight: 400;
  }



  .input-account-ledger-list>input {
    width: 150px;
    position: relative;
    left: 10px;
    margin-top: 15px;
  }

  .input-region-ledger-list>input {
    margin-left: 29px;
    margin-top: 60px;
  }

  /*-------------- SHOW NAME-------- */
  .show-name-dashboard-ledger-list {
    display: flex;
    align-items: center;
  }

  /*-------------- table div-------- */

  .third-ledger-list {
    width: 98%;
    height: 200px;

  }

  .table-ledger--list {
    border-collapse: collapse;
    width: 100%;
    overflow: auto;

  }

  .table-ledger--list td,
  .table-ledger--list th {
    padding: 1%;
    font-size: small;
  }


  .table-ledger--list tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  .table-ledger--list tr:hover {
    background-color: #d9d9d9;
  }

  .table-ledger--list th {
    padding-top: 1%;
    padding-bottom: 1%;
    text-align: center;
    background-color: #015998;
    color: white;

  }

  .table-ledger--list tr>td {
    text-align: center;
    cursor: pointer;
    font-size: small;
    font-weight: 500;
  }

  .table-ledger--list tr>td>input {
    width: 50px;
  }

  .table-ledger--list tr>td>select {
    width: 50px;
  }



  /* --------REMARKS-------- */

  .remarks-ledger-list {
    display: flex;
    align-items: center;
    margin-left: 22%;
    width: auto;
    margin-top: 40px;
  }

  .remarks-ledger-list-text {
    display: flex;
    align-items: center;
    border-radius: 10px;
    height: 20px;
    background-color: #015998;
    color: white;
    padding: 4px;
    font-size: large;
    font-weight: 500;
  }

  .remarks-ledger-list-input>input {
    width: 150px;
    margin-left: 10px;
    background: #D9D9D9;
    border: none;
    margin-right: 10px;
    border-radius: 10px;
    margin-top: 0px;
  }



  /* foooter */

  .footer-ledger-list {
    background-color: #015998;
    margin-top: 60px;
    height: 60px;
    width: 98%;
  }

  .footer-ledger-list-div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: white;
    gap: 30px;
    font-size: larger;
    font-weight: 500;
  }

  .footer-ledger-list-div>input {
    width: 70px;
    background: #D9D9D9;
    border: none;
    margin-right: 10px;
    border-radius: 10px;
    margin-top: 0px;
  }

}







@media screen and (min-width : 855px) and (max-width: 1141px) {
  .main-ledger-div {
    background-color: white;
    padding-left: 1%;
    padding-top: 10px;
    padding-right: 1%;
    width: 500px;
  }

  .header-ledger-list {
    background: #015998;
    border-radius: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: large;
    height: 40px;
  }

  /*-------------- SHOW NAME-------- */
  .show-name-dashboard-ledger-list {
    display: flex;
    align-items: center;
  }


  .input-account-ledger-list>input {
    width: 150px;
    position: relative;
    left: 10px;
    margin-top: 15px;
  }

  .input-region-ledger-list>input {
    margin-left: 29px;
    margin-top: 60px;
  }

  .second-rightdiv-ledger-list {
    display: flex;
    flex-direction: column;
    align-items: center;

  }

  /*-------------- table div-------- */

  .third-ledger-list {
    width: 100%;
    height: 200px;

  }

  .table-ledger--list {
    border-collapse: collapse;
    width: 100%;
    overflow: auto;

  }

  .table-ledger--list td,
  .table-ledger--list th {
    padding: 1%;
    font-size: small;
  }


  .table-ledger--list tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  .table-ledger--list tr:hover {
    background-color: #d9d9d9;
  }

  .table-ledger--list th {
    padding-top: 1%;
    padding-bottom: 1%;
    text-align: center;
    background-color: #015998;
    color: white;

  }

  .table-ledger--list tr>td {
    text-align: center;
    cursor: pointer;
    font-size: small;
    font-weight: 500;
  }

  .table-ledger--list tr>td>input {
    width: 50px;
  }

  .table-ledger--list tr>td>select {
    width: 50px;
  }



  /* --------REMARKS-------- */

  .remarks-ledger-list {
    display: flex;
    align-items: center;
    margin-left: 22%;
    width: auto;
    margin-top: 40px;
  }

  .remarks-ledger-list-text {
    display: flex;
    align-items: center;
    border-radius: 10px;
    height: 20px;
    background-color: #015998;
    color: white;
    padding: 4px;
    font-size: large;
    font-weight: 500;
  }

  .remarks-ledger-list-input>input {
    width: 150px;
    margin-left: 10px;
    background: #D9D9D9;
    border: none;
    margin-right: 10px;
    border-radius: 10px;
    margin-top: 0px;
  }



  /* foooter */

  .footer-ledger-list {
    background-color: #015998;
    margin-top: 60px;
    height: 60px;
    width: 100%;
  }

  .footer-ledger-list-div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: white;
    gap: 30px;
    font-size: larger;
    font-weight: 500;
  }

  .footer-ledger-list-div>input {
    width: 70px;
    background: #D9D9D9;
    border: none;
    margin-right: 10px;
    border-radius: 10px;
    margin-top: 0px;
  }

}