/* NotificationList.css */

/* Style for the container that holds notifications */
.notification-popup-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding: 20px;
    margin-top: 37px
}

/* Style for each individual notification */
.notification-popup {
    flex: 0 1; /* Allow notifications to grow and take equal space in a row */
    border: 1px solid #ddd;
    padding: 15px;
    max-width: 400px; /* Adjust the max width as needed */
    background-color: #f9f9f9; /* Background color of notifications */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s; /* Add a hover effect */
    height: 280px;
}

/* Hover effect for notifications */
.notification-popup:hover {
    transform: scale(1.02); /* Enlarge the notification on hover */
}

/* Style for the notification header */
.notification-header {
    background-color: #007BFF; /* Header background color */
    color: #fff; /* Text color for the header */
    padding: 8px;
    text-align: center;
}

.notification-header h2 {
    margin: 0;
    line-height: 20px;
}

/* Style for the notification content */
.notification-content {
    padding: 10px;
    color: #333; /* Text color for the content */
}
.text-h2{
font-size: 16px;
}
/* Add any additional styles or customizations you need */
