.navbar-right-side{
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
    width: 800px;
    padding: 10px;
    background-color: rgb(255, 255, 255);
}
.navbar-right-side-logo{
    display: flex;
    align-items: center;
    gap: 10px;
}

.navbar-right-side > input{
    width: 40%;
    margin-top: 0px;
}

.navbar-logo-size{
    height: 20px;
    width: 40px;
    cursor: pointer;
}


@media only screen and (max-width: 850px) {
    .navbar-right-side{
        display: flex;
        align-items: center;
        gap: 10px;
        justify-content: space-between;
        width: 100.1%;
        padding: 10px;
        background-color: rgb(255, 255, 255);
        margin-left:10%;
    }
}