/* .form-box-firmGen-profile {
  font-family: Arial, sans-serif; 
  color: #333; 
}

.two-columns-three-profile,
.two-columns-three-profile-s {
  margin-bottom: 20px; 
}

.inner-two-columns-three-profile {
  display: flex;
}

.key-value-pair {
  display: flex;
  align-items: center;
  margin-bottom: 10px; 
}

.key-value-pair label {
  width: 150px; 
  flex-shrink: 0;
}

.key-value-pair p,
.key-value-pair input {
  margin-left: 10px; 
}

.edit-profile-button {
  background-color: #4caf50; 
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.edit-profile-button:hover {
  background-color: #45a049; 
}
 */










 .form-box-firmGen-profile {
  font-family: Arial, sans-serif;
  color: #333;
  /* flex: 1; */
  width: 900px;
  margin-left: 34px;
}

.heading-firm-gen-1-profile{
  align-items: center;
  justify-content: center;
  align-self: center;
}

.user-profile-table {
  width: 100%;
  border-collapse: collapse;
  /* margin-bottom: 40px; */
}

.user-profile-table td,
.user-profile-table th {
  border: 1px solid #dddddd;
  padding: 8px;
  /* text-align: left; */
}

.user-profile-table th {
  background-color: #f2f2f2;
}

.edit-profile-button {
  background-color: #4caf50;
}