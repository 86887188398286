/* Style for the CouponList component */
.coupon-container {
  font-family: Arial, sans-serif;
  background-color: #f7f7f7;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  max-height: 500px; /* Set a maximum height */
  overflow-y: auto; /* Add a vertical scrollbar if content exceeds max height */
  width: 66rem;
  margin-left: 43px;
  margin-top: 17px;
};

.coupon-container h1 {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
};

.coupon-container ul {
  list-style-type: none;
  padding: 0;
}

.coupon-container li {
  background-color: #fff;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.coupon-container h2 {
  font-size: 18px;
  color: #007bff; /* Blue color for coupon codes */
}

.coupon-container p {
  font-size: 16px;
  color: #555;
}
