body{
    position: absolute;
 }

 
 .c-form-product-view{
   display: grid;
   justify-content: center;
 }


 .two-columns-product-view{
    margin-top: 1rem;
    display: flex;
 }

 .two-columns-product-view-top-row{
    margin-top: 7rem;
    display: flex;
 }
 .productName-product-view{
     gap: 1rem;
     display: flex;
     justify-content: center;
 }

 fieldset {
   border: 0;
}
