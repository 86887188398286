.main-div-ledger-entry-main {
  background-color: #015998;
}


.sendC-DButton {
  width: 6rem;
  height: 28px;
  border-radius: 3px;
  color: black;
}

.ledger--entry {
  background: #015998;
  width: 61.5rem;
  height: 38px;
  color: white;
  border-radius: 5px;
  margin-top: 10px;
  margin-left: 5%;
  margin-right: 5%;
  display: flex;
  justify-content: center;
}

.main-div-ledger-entry {
  width: 100%;
}

.center-values-ledger-entry {
  display: flex;
  flex-direction: column;
  height: 100px;
  /* justify-content: space-between; */
  /* background-color: red; */
 
  margin-top: 0px;
  /* margin-bottom: 20px; */
}

.AC-RegionDiv-ledger-entry {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-left: 58px;
  height: 6rem;
}

.feildsDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.inputDiv {
  margin-left: 10px;
}

.acntInput {
  width: 20rem;
}

.regionInput {
  margin-right: 9rem;
}

.dateAndBalanceDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  /* margin-right: 6rem; */

}

.dateBalanceInfoDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-top: 25px; */
}

.docLabel {
  margin-right: 1rem;
}

.creditDebitDiv {
  display: flex;
  background: #015998;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 3rem;
}

.creditDiv,
.debitDiv {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 15rem;
}

.inner-div {
  margin-left: 15rem;
  margin-top: -7rem;
  align-items: center;
  display: grid;
  justify-content: right;
}


/* second half for ledger paymet entry title and values */

/* new css  for heading and entries for ledger*/
/* Common styles */
.quantity-box-top-top-ledger-create {
  overflow-x: auto; /* Enable horizontal scrolling */
  width: 100%;
  margin-top: 20px;
}

#customers-ledger-entry {
  border-collapse: collapse;
  width: 100%;
}

thead {
  display: block; /* Create block layout for thead */
}

thead th {
  position: sticky;
  top: 0;
  /* Adjust other styles as needed */
}

thead td:last-child {
  /* padding-left: 20px; */
  padding-right: 30px;
}




/* Targeting specific header cells */
thead th:nth-of-type(1),
thead th:nth-of-type(2),
thead th:nth-of-type(4),
thead th:nth-of-type(5),
thead th:nth-of-type(6),
thead th:nth-of-type(7),
thead th:nth-of-type(8),
/* Adding 'Date' heading specifically */
thead th:nth-of-type(3) {
  background-color: #015998; /* Change background color for specified headings */
  color: #fff; /* Change text color for better readability */
}

tbody {
  display: block; /* Create block layout for tbody */
  height: 195px; /* Set a fixed height for scrollable body */
  overflow-y: auto; /* Enable vertical scrolling */
  padding-left: 10px;
  padding-right: 10px;
}

tbody tr {
  border-bottom: 1px solid #ddd;
}

tbody td {
  padding: 8px;
  text-align: left;
}

.white {
  background-color: #fff;
}

.grey {
  background-color: #f2f2f2;
}

/* Adjustments for inputs, selects, and buttons within table cells */
input,
select {
  /* width: 100%; */
  height: 30px;
  /* padding: 6px; */
  /* box-sizing: border-box; */
}


/* for normal input box out side of payment entries */
input[type="text"],
input[type="number"], 
input[type="date"]
{
  width: 100%;
  padding: 6px; /* Adjust padding for space inside the input */
  height: 30px; /* Set the height of the normal input boxes */
  box-sizing: border-box;
}

.span-width{
  width: 25px;
  /* left: -10; */
}

/* inside payment entries input box size */
tbody tr td input
{
  width: 100%;
  padding: 6px;
  height: 30px !important; 
  /* box-sizing: border-box; */
}




button {
  padding: 4px 8px;
  border: none;
  cursor: pointer;
}

/* ledger entry title and values closed */
/* Adjustments for inputs, selects, and buttons within table cells */

button {
  padding: 4px 8px;
  border: none;
  cursor: pointer;
}




/* added new  */

#amount-receive {
  display: flex;
  right: 0;
  bottom: 0;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  background-color: rgb(247, 245, 245); /* Add a background color for visualization */
  position: fixed;
  width: 78%;

}

#amount-receive-total {
  font-size: 20px;
  color: rgb(8, 8, 17); /* Set a different color for text */
  font-weight: bold;
}

#inputid {
  height: 30px;
  width: 140px;
}




/* new added css is here  */

.acname{
   margin-left:20px;
}

.dateLabeldocLabel{
  /* margin-right: 95px; */
}

.dateLabeldocLabelinput{
  height: 30px;
  width: 150px;
  margin-left:5px;
  
}














/* for remarks pop up show css */

.input-remarks-modal {
  /* position: fixed; */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.input-remarks-modal-main {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5); /* Box shadow for a pop-up effect */
}

.display-none {
  display: none;
}


/*  */

/* styles.css */

/* Modal background styles */

.input-remarks-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(196, 192, 192, 0.5); /* Semi-transparent black */
  backdrop-filter: blur(1px); /* Apply blur effect to the background */
  z-index: 1000; /* Ensure the pop-up appears on top of everything */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Modal content styles */
.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
}

/* Other styles for your page content */
/* ... */
