.icon-container {
  display: flex;
  align-items: center; /* Vertically align the content */
  cursor: pointer;
}

.heading_1{
background-color: whitesmoke;
margin-top: 1.7%;
margin-left: 4%;
border-radius: 5px;
display: flex;
align-items: center;
box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.2);
height: 40px;
}

/* .heading_1 > button :hover {
 background-color: #ffffff; /* Darker blue on hover */


.head_span_1{
margin-left: 5px;
font-size: 14px;
color: black;
}
.head_span_2{
margin-left: 3%;
font-size: 14px;
color: black;
}
.heading_2{
background-color: #015998;;
margin-top: 1.7%;
margin-left: 4%;
border-radius: 5px;
text-align: center;
height: 40px;
box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.2)
}
.head_span_3{
  color: white;
}

.profileBoxContainer_1 {
position: absolute;
top: 56px;
right: 1px;
z-index: 1;
width: 17%;
border: 1px solid white;
background: #ffffff;
box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
border-radius: 6px;
cursor: pointer;
}

.innerBoxDiv_1 {
background: whitesmoke;
display: flex;
align-items: center;
border: 1px solid white;
border-radius: 6px;
height: 53px;
};

.heading-Div_1 {
display: flex;
justify-content: center;
color: #575757;
padding-left: 1rem;
};

.iconDiv_1 {
color: black;
margin-left: 12px;
}

.ellipsis-container{
font-size: 14px;
margin-left: 6rem;
}



.upgrade-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  color: white;
  font-size: 10px;
  width: 8rem;
  position: relative;
  left: 3%;
  background-color: #3498db;
  height: 28px;
  align-self: center;
}



/* Your existing CSS */

/* Your existing CSS */

/* Add overflow-x property for horizontal scrolling */
/* @media only screen and (max-width: 600px) { */
  .icon-container,
  .heading_1,
  .heading_2,
  .profileBoxContainer_1,
  .innerBoxDiv_1 {
    overflow-x: auto; /* Add scrolling for small devices */
    white-space: nowrap; /* Prevent wrapping of content */
  }
/* } */
