.mainApp{
    display: flex;
}

.mainpage-app{
    display: flex;
    flex-direction: column;
}

.mainpage-app-left-logo{
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.logo-hegan{
    margin-top: 5px;
    height: 37px;
    width: 37px;
    /* overflow-y: auto; */
}

@media only screen and (max-width: 943px){
    .mainApp{
        display: flex;
        flex-direction: column;
        width: 100%;
    }
}

/* Styles for smaller laptops and tablets */
@media (max-width: 1366px) {
  /* Add your styles for smaller laptops and tablets here */
}

/* Styles for standard laptops and small desktop screens */
@media (min-width: 1367px) and (max-width: 1600px) {
  /* Add your styles for standard laptops and small desktop screens here */
}

/* Styles for larger laptops and larger desktop screens */
@media (min-width: 1601px) and (max-width: 1920px) {
  /* Add your styles for larger laptops and larger desktop screens here */
}

/* Styles for extra-large desktop screens */
@media (min-width: 1921px) {
  /* Add your styles for extra-large desktop screens here */
}



/* for loading screen loading circule  */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 9999;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Media query for mobile devices */
@media (max-width: 767px) {
  .loading-container {
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}



/* we are udner maintnace file  */

.under-maintenance-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%; /* Adjust the width as needed */
  position: fixed; /* Keep the container fixed in the center */
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8); /* Adjust the background color and opacity as needed */
  z-index: 9999; /* Ensure the container appears above other elements */
}

.under-maintenance-message {
  text-align: center;
  padding: 20px;
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 400px; /* Adjust the maximum width as needed */
  width: 80%; /* Adjust the width as needed */
}

.under-maintenance-message h2 {
  margin-bottom: 10px;
}

.under-maintenance-message p {
  margin-bottom: 0;
}
