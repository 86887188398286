/* 
    body {
      font-family: Arial, Helvetica, sans-serif;
      overflow-x: auto;
    }
  
    .container-product-generate {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  

    .form-box-product-generate {
      background-color: white;
      width: 70vw;
      height: 90vh;
      border-radius: 10px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
      padding: 20px;
      margin-left: 35px;
      margin-top: 10px;
    }
  
    .c-form-product-generate {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  
    .two-columns-product-generate {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
    }
  
    .c-form-label-for-product-generator {
      font-weight: bold;
    }
  
    input[type="text"],
    textarea {
      width: 100%;
      height: 40px;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
      outline: none;
    }
  
    .button-sumbit-purchase-gen {
      background-color: #015998;
      border: none;
      color: white;
      height: 40px;
      width: 150px;
      border-radius: 8px;
      font-weight: bold;
      cursor: pointer;
      align-self: center;
    } */









    body {
        font-family: Arial, Helvetica, sans-serif;
        overflow-x: auto;
      }
      
      .container-product-generate {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow-x: auto;
      }
      
      .form-box-product-generate {
        background-color: white;
        width: 900px;
        height: 600px;
        border-radius: 10px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
        padding: 20px;
        margin-left: 35px;
        margin-top: 10px;
      }
      
      .c-form-product-generate {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
      
      .two-columns-product-generate {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
      }
      
      .c-form-label-for-product-generator {
        font-weight: bold;
        color: rgb(1, 0, 0);
        font-size: 13px;
      }
      
      input[type="text"],
      textarea {
        width: 100%;
        height: 40px;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        outline: none;
      }
      
      .button-sumbit-purchase-gen {
        background-color: #015998;
        border: none;
        color: white;
        height: 40px;
        width: 150px;
        border-radius: 8px;
        font-weight: bold;
        cursor: pointer;
        align-self: center;
      }
      
      @media screen and (max-width: 850px) {
        body {
          overflow-x: auto;
        }
      }
      