

  .carousel-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
}

.image-container {
  position: relative;
  width: 600px;
  height: 300px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
  /* box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2); */


.image-container img {
  width: 67.5%;
  height: 80%;
  position: absolute;
  top: 50%;
  left: -100%;
  transition: transform 1s, opacity 1s;
  opacity: 0;
}

.image-container img:hover {
  transform: translate(-50%, -50%) perspective(1200px) scale(1.2) rotateY(100deg);
  z-index: 1;
 cursor: pointer;
}



.image-container img.active {
  left: 50%;
  transform: translate(-50%, -50%) perspective(1200px) rotateY(360deg);
  opacity: 1;
}





