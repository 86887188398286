.upgrade_plan {
    width: 890px;
    border: 1px solid #ccc;
    padding: 20px;
    margin: 20px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    margin-left: 120px;
    height: 700px;
    background-color: #E2EEF4;
}

.text-upgrade h4 {
  font-size: 18px;
  color: #fff;
  margin: 0;
  text-align: center;
}
.text-upgrade{
  background-color: #015998;
  border-radius: 4px;
}

.heading-text h5 {
  font-size: 14px;
  color: #555;
  margin: 10px 0;
}

.proceed-button > button:hover {
  background-color: #0083b3; /* Darker blue on hover */
}

.paragraph-1{
    margin-top: 5px;
    line-height: 24px;
    color: black;
    padding: 10px;
};
.list-rate{
    margin-top: 10px;
    line-height: 22px;
    color: black;
}

.list-footer{
    color: black;
    line-height: 26px;
    margin-top: 7px
}
.proceed-button{
    display: flex;
    justify-content: center;
}

.proceed-button > button{
    background-color: #015998;
    color: #fff;
    padding: 10px 50px 10px 50px;
    border-radius: 4px;
    font-size: 15px;
    font-family: sans-serif;
}