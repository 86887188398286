.Productlist-main {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  background-color: white;
}

.Productlist-main {
  width: 83vw;
  overflow-x: hidden;
}

.Productlist-header {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #015998;
  color: white;
  font-size: small;
  font-style: normal;
  border-radius: 10px;
  font-family: Arial, Helvetica, sans-serif;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: -2px;
}

.table--customer--Productlist {
  /* height: 580px; */
  top: 20px;
  margin-left: 4%;
  overflow: auto;
}

.table-customer-heading-Productlist>th {
  position: sticky;
  top: 0;
  font-size: small;
}

.table-top-Productlist {
  display: flex;
  justify-content: space-between;
}

.show-name-Productlist {
  display: flex;
  align-items: center;
  margin-left: 5%;
}

.show-name-Productlist>input {
  margin-left: 10px;
}

.show-name-Productlist>select {
  margin-left: 10px;
  padding: 6px;
  border-radius: 5px;
  border-color: #aba4a4;
  width: 70px;
}

.search-name-Productlist {
  margin-right: 5%;
}

.search-name-Productlist>input {
  padding: 10px;
  border: 1px solid #aba4a4;
  border-radius: 4px;
  width: 200px;
  margin-top: 16px;
}

.data-table-Productlist>td>p {
  text-align: left;
  overflow: auto;
}

#customers-Productlist {
  border-collapse: collapse;
  width: 100%;
}

#customers-Productlist td,
#customers-Productlist th {
  padding: 4px;
}


.first-Productlist {
  background-color: #f2f2f2;
  border: none;
  width: 50px;
  height: 20px;
  border: 0px solid;
  text-align: center;
}

.second-Productlist {
  background-color: #ffffff;
  border: none;
  width: 50px;
  height: 20px;
  border: 0px solid;
  text-align: center;
}

#customers-Productlist tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers-Productlist tr:hover {
  background-color: #d9d9d9;
}

#customers-Productlist th {
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: #015998;
  color: white;
  text-align: center;
}

#customers-Productlist tr>td {
  cursor: pointer;
}

.span-width_product {
  display: block;
  white-space: nowrap;
}

.csv-Productlist,
.pdf-Productlist,
.copy-Productlist {
  height: 50px;
  width: 20px;
  margin: 10px;
}

.logo-color-Productlist {
  background-color: #015998;
  padding: 10px;
  border-radius: 100px;
  color: white;
  font-size: xx-large;
  margin-top: 50%;
}

button {
  margin: 3px;
  cursor: pointer;
}

.Product-list-buttons-Productlist {
  margin: 5px;
}

.add-Productlist {
  background-color: white;
  border: 1px solid black;
  border-radius: 6px;
  text-decoration: none;
  padding: 5px;
  margin: 5px;
  font-size: x-small;
}

.update-Productlist {
  background-color: #015998;
  border-radius: 6px;
  border: #015998;
  padding: 5px;
  color: white;
  text-decoration: none;
  margin: 5px;
  font-size: x-small;
}

.delete-Productlist {
  background-color: #f91607;
  border-radius: 6px;
  padding: 5px;
  text-decoration: none;
  color: white;
  margin: 5px;
  font-size: x-small;
}

#appadd {
  white-space: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
}

.action-button-Product-table {
  display: flex;
  justify-content: center;
}

.action-button-Product-table>button {
  border-radius: 5px;
  border: #015998;
  width: 50px;
  padding: 2px;
  border: 2px solid black;
}

.view-sale-list {
  background: #015998;
  color: white;
  border: none;
}

.view-sale-list>a {
  color: white;
  text-decoration: none;
}

.edit-sale-list {
  background-color: white;
  color: black;
  border: rgb(85, 26, 26);
}

.edit-sale-list>a {
  color: black;
  text-decoration: none;
}

.del-sale-list {
  background-color: #f91607;
  color: white;
  align-items: center;
  display: flex;
  justify-content: center;
}

/* FOR MOBILE PPHONE SCREEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEN */

@media screen and (max-width: 854px) {
  .main-Productlist-file {
    margin: 5%;
    width: 300px;
  }

  .Productlist-main {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
    background-color: white;
    width: 100%;
  }

  .Productlist-header {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #015998;
    color: white;
    font-size: small;
    height: 30px;
    width: 300px;
    font-family: Arial, Helvetica, sans-serif;
    margin-left: 0;
    margin-right: 0;
  }

  .table--customer--Productlist {
    height: 280px;
    margin-left: 0px;
    overflow: auto;
  }

  .table-customer-heading-Productlist>th {
    position: sticky;
    top: 0;
    font-size: small;
  }

  .table-top-Productlist {
    display: flex;
    flex-direction: column;
  }

  .show-name-Productlist {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 5%;
  }

  .show-name-Productlist>input {
    margin-left: 10px;
  }

  .search-name-Productlist {
    margin-right: 5%;
  }

  .search-name-Productlist>input {
    padding: 10px;
    border: 1px solid #aba4a4;
    border-radius: 4px;
    width: 278px;
  }

  .data-table-Productlist>td>p {
    text-align: left;
    overflow: auto;
  }

  #customers-Productlist {
    border-collapse: collapse;
    width: 100%;
  }

  #customers-Productlist td,
  #customers-Productlist th {
    padding: 8px;
  }

  .first-Productlist {
    background-color: #f2f2f2;
    border: none;
    width: 90px;
    height: 20px;
    border: 0px solid;
    text-align: center;
  }

  .second-Productlist {
    background-color: #ffffff;
    border: none;
    width: 90px;
    height: 20px;
    border: 0px solid;
    text-align: center;
  }

  #customers-Productlist tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  #customers-Productlist tr:hover {
    background-color: #d9d9d9;
  }

  #customers-Productlist th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: #015998;
    color: white;
  }

  #customers-Productlist tr>td {
    text-align: center;
    cursor: pointer;
  }

  .csv-Productlist,
  .pdf-Productlist,
  .copy-Productlist {
    height: 15px;
    width: 15px;
    margin: 15px;
  }

  .logo-color-Productlist {
    background-color: #015998;
    padding: 10px;
    border-radius: 100px;
    color: white;
    font-size: xx-large;
    margin-top: 50%;
  }

  button {
    margin: 3px;
    cursor: pointer;
  }

  .Product-list-buttons-Productlist {
    margin: 5px;
  }

  .add-Productlist {
    background-color: white;
    border: 1px solid black;
    border-radius: 6px;
    text-decoration: none;
    padding: 5px;
    margin: 5px;
  }

  .update-Productlist {
    background-color: #015998;
    border-radius: 6px;
    border: #015998;
    padding: 5px;
    color: white;
    text-decoration: none;
    margin: 5px;
  }

  .delete-Productlist {
    background-color: #f91607;
    border-radius: 6px;
    padding: 5px;
    text-decoration: none;
    color: white;
    margin: 5px;
  }

  .action-button-Product-table {
    display: flex;
    justify-content: center;
  }

  .action-button-Product-table>button {
    background: #015998;
    border-radius: 0.5rem;
    border: #015998;
    width: 50px;
    color: white;
  }
}
