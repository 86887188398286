
.sidebar-button {
  padding: 10px;
  background-color: #0f0f0f;
  cursor: pointer;
  border-radius: 10px;
  height: 50px;
  width: 200px;
  color: white;
}

.productImage {
  height: 25px;
  width: 25px;
  background: rgb(249, 249, 249, 0.7);
}

.sidebar-button:hover {
  background-color: #fffafa;
  transition-duration: 2ms;
  color: rgb(0, 0, 0);
}

.triple-dot-slide {
  position: relative;
  left: 100%;
  top: 26px;
}

.mainpage-app-left-logo {
  display: flex;
  flex-direction: row;
  /* font-weight: 700; */
  margin-bottom: 20px;
  justify-content: space-around;
  align-items: center
};

.header-bar-main{
  position: fixed;
  top: 0;
  left: 0;
  width: 250px; /* Set the width of the sidebar */
  height: 100%; /* Make it cover the full height of the viewport */
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: hidden;
};

/* css popup for refferel code  */

/* CSS for centering the popup */
.custom-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Adjust the z-index as needed */
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
