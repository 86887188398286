.navbar-left-side{
    display: flex;
    align-items: center;
    gap: 10px;
}

.navbar-right-side{
    display: flex;
    align-items: center;
    gap: 10px;
}

.navbar-right-side > input{
    width: 90%;
    margin-top: 0px;
}

.navbar-logo-size{
    height: 20px;
    width: 40px;
    cursor: pointer;
}

.movetoDash{
    cursor: pointer;
}

.left-side-nav-bar{
max-height: 675px;
overflow-x: hidden;
max-width: 400px;
overflow-y: auto;
}