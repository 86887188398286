.printInvoiceContainer {
    position: absolute;
    top: 42rem;
    left: 33rem;
    z-index: 1;
    width: 15rem;
    border: 1px solid white;
    background: #ffffff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    cursor: pointer;
  }
  
  .printInnerBoxDiv {
    background: whitesmoke;
    display: flex;
    align-items: center;
    border: 1px solid white;
    border-radius: 6px;
  }
  
  .printHeadingDiv {
    display: flex;
    color: #575757;
    width: 75%;
  }
  
  .printIconDiv {
    color: black;
    margin-left: 12px;
  }
  