.refferal-page{
    background-color: white;
    border-radius: 10px;
    height: 22rem;
    /* margin-left: 60%; */
    width: 28rem;
    position: relative;
    left: 18rem;
    top: 6rem;
};

.icon-coupen{
    width: 180px;
    height: 180px;
    margin-left: 116px 
}
.code-input{
    margin-left: 84px;
}
.footer-picture{
    position: relative;
    top: 12px;
    right: 7px;
}
.pic-div{
display: flex;
justify-content: space-between;
}

.middle-icon{
    margin-left: 5rem;
    margin-top: 2rem;
}

#head-5{
    color: white;
    margin-left: 7px; 
    margin-top: 6px;
    font-family: monospace;
    font-size: 18px;
}
.coupen-text{
    display: flex;
}
#head-text{
    text-align: center; 
    color: #fff;
    padding-top: 2px;
    font-family: initial;
};

/* #innner-div-refferal{
    background-color: #015998;
    height: 300px;
    border-radius: 10px;
} */