.Productlist-main_marketplace {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  background-color: white;
}

.Productlist-main_marketplace {
  width: 80vw;
  overflow-x: hidden;
}

.table--customer--Productlist_marketplace {
  /* height: 580px; */
  top: 20px;
  margin-left: 4%;
  overflow: auto;
  height:28rem;
}

.table-customer-heading_marketplace>th {
  position: sticky;
  top: 0;
  font-size: small;
}

.table-top-Productlist_marketplace {
  display: flex;
  justify-content: space-between;
}

.show-name-Productlist_marketplace {
  display: flex;
  align-items: center;
  margin-left: 5%;
}

.show-name-Productlist_marketplace>input {
  margin-left: 10px;
}

.show-name-Productlist_marketplace>select {
  margin-left: 10px;
  padding: 6px;
  border-radius: 5px;
  border-color: #aba4a4;
  width: 70px;
}

.search-name-Productlist_marketplace {
  margin-right: 3%;
  margin-top: 6px;
}

.search-name-Productlist_marketplace>input {
  padding: 10px;
  border: 1px solid #aba4a4;
  border-radius: 4px;
  width: 200px;
  margin-top: 16px;
}

#customers-Productlist_marketplace {
  border-collapse: collapse;
  width: 90rem;
}

#customers-Productlist_marketplace td,
#customers-Productlist_marketplace th {
  padding: 4px;
}

#customers-Productlist_marketplace tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers-Productlist_marketplace tr:hover {
  background-color: #d9d9d9;
}

#customers-Productlist_marketplace th {
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: #015998;
  color: white;
  text-align: center;
}

#customers-Productlist_marketplace tr>td {
  cursor: pointer;
  padding: 15px 10px 15px 10px;
}

.span-width_product_marketplace {
  display: block;
  white-space: nowrap;
}

.csv-Productlist_marketplace,
.pdf-Productlist_marketplace,
.copy-Productlist_marketplace {
  height: 50px;
  width: 20px;
  margin: 10px;
}

button {
  margin: 3px;
  cursor: pointer;
}

.outputlist1-products-list > td > input{
  text-align:center;
}


/* Media query for desktop sizes */
@media (max-width: 992px) {
  /* Add styles for smaller screen sizes here */
  .Productlist-main_marketplace {
    /* Adjust font size, padding, or any other styles for smaller screens */
    font-size: 14px;
    padding: 10px;
  }

  .table--customer--Productlist_marketplace {
    /* Adjust styles for the table or container on smaller screens */
    margin-left: 2%;
    width: 100%;
  }

  .show-name-Productlist_marketplace {
    /* Modify styles for the container with class "show-name-Productlist_marketplace" */
    margin-left: 2%;
  }

  .search-name-Productlist_marketplace>input {
    /* Modify styles for the search input on smaller screens */
    width: 100%;
  }

  #customers-Productlist_marketplace {
    /* Adjust the width of the table for smaller screens */
    width: 100%;
  }

  .span-width_product_marketplace {
    /* Modify styles for elements with class "span-width_product_marketplace" on smaller screens */
    white-space: normal;
  }

  .csv-Productlist_marketplace,
  .pdf-Productlist_marketplace,
  .copy-Productlist_marketplace {
    /* Adjust the size and margins of the buttons on smaller screens */
    height: 40px;
    width: 16px;
    margin: 5px;
  }
  
  button {
    /* Modify styles for buttons on smaller screens */
    margin: 2px;
  }
  /* Add any additional responsive styles as needed */
}
