body{
    position: absolute;
 }
 .heading-prod-update{
    background: #015998;
    border-radius: .5rem;
    border: #015998;
    position: absolute;
    width: 637px;
    height: 20px;
    left: 499px;
    top: 112px;
    font-family:Arial, Helvetica, sans-serif;
    font-style:normal;
    font-size: 20px;
    margin-left: -125px;
    margin-top: -70px;
    margin-left: -420px;
    text-align: center;
    color: #FDF9F9;
    padding-bottom: 5px;
  
 }

 .button-sumbit-product-update{
    background-color: #015998;
    border: #015998;
    margin-left: 16.5rem;
    color: white;
    height: 38px;
    width: 120px;
    border-radius: 8px;
}
 .form-box-prod-update{
    background-color: white;
 }
 
 .c-form-btn-prod-update{
    margin-left: 13rem;
 }
 
 .c-form-prod-update{
    display: grid;
    justify-content: center;
    height: 43rem;
 }

 .c-form-label{
    line-height: 20px;
    padding-left: 1rem;
    padding-top: 0rem;
    align-items: center;
    display: flex;
    margin-left: -1rem;
    font-family:Arial, Helvetica, sans-serif;
    font-style:normal;
    font: bolder;
    font-weight:540;
    color: #000000;
}
.c-form-input{
    width: 18rem;
    height: 1.4rem;
    background: #FFFFFF;
    border: 1px solid #000000;  
    border-radius: 4px;
    margin-top: .3rem;
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.2);
}

 .productOut-prod-update{
     margin-top: -6rem;
     margin-left: -34rem;
     margin-bottom: 5rem;
     font-family: Arial, Helvetica, sans-serif;
     font-size: larger;
 }



 .two-columns-prod-update{
    margin-top: 1rem;
    display: flex;
 }


 .productName-prod-update{
     gap: 1rem;
     display: flex;
     justify-content: center;
 }
 .textarea-prod-update{
        height: 100px;
        width: 18rem;
    }
 

    @media screen and (max-width : 850px){
      body{
          position: absolute;
       }
       .heading-prod-update{
          background: #015998;
          border-radius: .5rem;
          border: #015998;
          position: absolute;
          width: 200px;
          height: 20px;
          left: 499px;
          top: 112px;
          font-family:Arial, Helvetica, sans-serif;
          font-style:normal;
          font-size: 20px;
          margin-left: -125px;
          margin-top: -70px;
          margin-left: -420px;
          text-align: center;
          color: #FDF9F9;
          padding-bottom: 5px;
       }
       
       .c-form-btn-prod-update{
           margin-left: 1rem;
       }
       
       .c-form-label-prod-update{
          line-height: 20px;
          padding-left: 1rem;
          padding-top: 0rem;
          align-items: center;
          display: flex;
          margin-left: -1rem;
          font-family:Arial, Helvetica, sans-serif;
          font-style:normal;
          font: bolder;
          font-weight:540;
          color: #000000;
       }
       fieldset {
          padding-top: 0rem;
          border: none;
          margin-left: -25px;
      }
       .c-form-input-prod-update{
           width: 18rem;
           height: 1.4rem;
           background: #FFFFFF;
           border: 1px solid #000000;  
           border-radius: 4px;
           margin-top: .3rem;
       }
       
       .c-form-prod-update{
          position: absolute;
          background-color: white;
          width: 100%;
          height: 1200px;
          overflow: hidden;
          margin-left:10%;
          margin-right: 10%;
       }
       
       .productOut-prod-update{
           margin-top: -6rem;
           margin-left: -34rem;
           margin-bottom: 5rem;
           font-family: Arial, Helvetica, sans-serif;
           font-size: larger;
       }
       .two-columns-prod-update{
          margin-top: 2rem;
          display: flex;
          flex-direction: column;
       }
       
       textarea {
           height: 100px;
           width: 18rem;
       }
       .productName-prod-update{
           gap: 1rem;
           display: flex;
           justify-content: center;
       }
       .textarea-prod-update{
           height: 4rem;
           width: 18rem;
           margin-top: 1rem;
       }
  }