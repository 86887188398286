.rs-picker-default .rs-picker-toggle.rs-btn {
  padding-top: 7px;
  padding-bottom: 7px;
  width: 250px;
}

.rs-picker-default .rs-picker-toggle {
  position: relative;
  z-index: 5;
  padding-right: 67px;
  display: inline-block;
  outline: none;
  cursor: pointer;
  color: #575757;
  border: 1px solid #e5e5ea;
  padding-left: 44px;
}
.rs-picker-toggle-caret {
  display: inline-block;
  margin-right: 240px;
  position: absolute;
  top: 8px;
  right: 12px;
  font-weight: normal;
  color: #8e8e93;
}

.rs-picker-toolbar-right-btn-ok { 
  text-align: center; 
  cursor: pointer;
  outline: 0 ; 
  border: none; 
  padding: 8px 12px; 
  font-size: 14px; 
  border-radius: 30px;
  color: #fff;
  background-color: #3498ff;
  width: 100px;
}

.rs-picker-menu .rs-calendar .rs-calendar-table-cell-content {
  padding-left: 0;
  padding-right: 0;
  border-radius: 21px;
  display: inline-block;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App{
  display: flex;
}

.total-overview-dashboard,  .middle-main-dashboard,  .sales-dashboard {
  width: 80.2vw;
  padding: 25px;

}

.button-flex-layout{
  display: flex;
  /* margin-left: 240px; */

};

.dashboard-div-dashboard{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 60.2vw;
}
.overview-graph-dashboard{
  border: 2px solid rgb(69, 5, 5);
}

.top-row-dashboard{
  display: flex;
  margin: 20px;
  gap: 20px;
  width: 70vw;

}

.top-row-dashboard > div {
  padding: 5px;
  width: 390px;
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  cursor: pointer;
}

.boxes-dashboard{
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.boxes-dashboard > div> p{
  color: #5B5B60;
}

.overview-graph-dashboard{
  display: flex;
  flex-direction: column;

}

.graphs-dashboard{
  display: flex;
  justify-content: space-between;
  height: 240px;
  margin-top: 10px;
  width: 70vw;
}



.table-customer-dashboard{
  height: 150px;
  /* overflow: scroll; */
  overflow-x: hidden;

}

.table-customer-heading-dashboard > th {
  position: sticky;
  top: 0;
}


.table-top-dashboard{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.show-name-dashboard{
  display: flex;
  align-items: center;
}

.show-name-dashboard > input{
  margin-left: 10px;
}
.search-name-dashboard > input{
  padding: 10px;
  border: 1px solid #ABA4A4;
  border-radius: 4px;
  width: 375px;
}

#customers-dashboard {
  border-collapse: collapse;
  width: 100%;
  /* overflow: auto; */
  margin-top: 10px;

}

#customers-dashboard td, #customers-dashboard th {
  padding: 8px;
  
}

  #customers-dashboard input {
    width: 90px;
    height: 20px;
    background-color:#36323237 ;
    border: 0px solid;
    text-align: center;
  }

#customers-dashboard tr:nth-child(even){background-color: #f2f2f2;}

#customers-dashboard tr:hover {background-color: #d9d9d9;}

#customers-dashboard th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #015998;
  color: white;
  
}
#customers-dashboard tr > td {
  text-align: center;
  cursor: pointer;
  
}

.csv-dashboard, .pdf-dashboard, .copy-dashboard{
  height: 50px;
  width: 20px;
  margin: 10px;
}

.logo-color-dashboard{
  background-color: #015998;
  padding: 10px;  
  border-radius: 100px;
  color: white;
  font-size: xx-large;
  margin-top: 50%;
}



.sales-color-dashboard{

  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50%;
}  
.sales-color-dashboard > h2{
  color: #440000;
  
}
.sales-color-dashboard > p {
font-weight: 500;
}
.user-color-dashboard{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  
}

.user-color-dashboard > h2{
  color: #E27A00;
}
.user-color-dashboard > p {
  font-weight: 500;
}

.order-color-dashboard{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 110%;
}

.order-color-dashboard > h2{
  color: #707070
}
.order-color-dashboard > p{
  font-weight: 500;
}

.regis-color-dashboard{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100px;
}

.regis-color-dashboard > h2{
  color: #1243A0;
}
.regis-color-dashboard > p{
  font-weight: 500;
}
.revenue-color-dashboard{
  color: #EB5757;
}

.mAvail-color-dashboard{
  color: #DEBB00;
}

.mShortage-color-dashboard{
  color: #F91607;
}

.mTotal-color-dashboard{
  color: #41AA11;
}





/* Add overflow-x property for horizontal scrolling */
@media only screen and (max-width: 600px) {
  .rs-picker-default .rs-picker-toggle.rs-btn,
  .rs-picker-default .rs-picker-toggle,
  .rs-picker-toggle-caret,
  .rs-picker-toolbar-right-btn-ok,
  .rs-picker-menu .rs-calendar .rs-calendar-table-cell-content,
  .total-overview-dashboard,
  .middle-main-dashboard,
  .sales-dashboard,
  .button-flex-layout,
  .dashboard-div-dashboard,
  .overview-graph-dashboard,
  .top-row-dashboard,
  .boxes-dashboard,
  .overview-graph-dashboard,
  .graphs-dashboard,
  .table-customer-dashboard,
  .table-customer-heading-dashboard,
  .show-name-dashboard,
  .search-name-dashboard,
  #customers-dashboard,
  .csv-dashboard,
  .pdf-dashboard,
  .copy-dashboard,
  .logo-color-dashboard,
  .sales-color-dashboard,
  .user-color-dashboard,
  .order-color-dashboard,
  .regis-color-dashboard,
  .revenue-color-dashboard,
  .mAvail-color-dashboard,
  .mShortage-color-dashboard,
  .mTotal-color-dashboard {
    overflow-x: auto; /* Add scrolling for small devices */
    white-space: nowrap; /* Prevent wrapping of content */
  }
}
