/* CSS for PaymentCalculator component */

.payment-container {
  background-color: #f0f0f0;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%; /* Full width of parent container */
  max-width: 600px; 
  /* Set a maximum width if needed */
  margin: 10px;
  margin-left: 180px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}



/* Other styles remain unchanged */


.user-details {
  margin-top: 20px;
  gap:80px;
}

.user-details > ul > li {
    padding:20px;
}

.user-details p {
  margin: 5px 0;
}

/* Style headings with strong tags */
.user-details strong {
  font-weight: bold;
}
