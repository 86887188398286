.graph1, .graph2{
    width: 100%;
    height: 80%;
}

@media screen and (max-width: 650px) {

    .graph1{
        margin-top: 50px;
    }

}