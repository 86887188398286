.saleInvoice-container {
  position: absolute;
  z-index: 1;
  background: white;
  width: 60rem;
  height: 7rem;
}

.saleInvoice-head {
  border: 1px solid black;
  height: 2rem;
}

.saleInvoice-headings {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  margin-right: 15px;
}

.saleInvoice-tableHeading,
.saleInvoice-table1,
.saleInvoice-table2 {
  border: 1px solid black;
}

.saleInvoice-productDetails th {
  border: none;
}

.sellerAndBuyer {
  display: flex;
  justify-content: space-around;
  border: 1px solid black;
}

.saleInvoice-tables {
  display: flex;
  border: 1px solid black;
}

.saleInvoice-table1 {
  width: 100%;
}

.saleInvoice-table2 {
  width: 50%;
}

.saleInvoice-tableHeading {
  text-align: center;
}

.saleInvoice-productDetails {
  border: 1px solid black;
  height: auto;
}

.saleInvoice-balance {
  border: 1px solid black;
}

.saleInvoice-footer {
  border: 1px solid black;
  height: auto;
  display: flex;
  justify-content: space-around;
}

.saleInvoice-rectangle {
  height: 25px;
  width: 70px;
  border: 1px solid black;
}

.saleInvoice-amountList {
  display: flex;
  flex-direction: column;
}

.saleInvoice-bankDetails {
  width: 20%;
}

.saleInvoice-costDetails {
  display: flex;
  justify-content: space-evenly;
  border: 1px solid black;
  margin-left: 2rem;
  width: 40%;
}

.saleInvoice-totalCost {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 21rem;
  margin-left: 4rem;
}

.saleInvoice-costRectangle {
  height: 24px;
  width: 120px;
  border: 1px solid black;
  margin: 1px;
  border-radius: 25px;
}

.saleInvoice-innerCostDiv {
  display: flex;
  justify-content: space-between;
}