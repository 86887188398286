.firmContainer {
  position: absolute;
  top: 12rem;
  /* right: 20px; */
  left: 22rem;
  z-index: 1;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  width: 53rem;
  background: whitesmoke;
  height: 15rem;
  overflow-y: scroll;
}
.productContainer {
  position: absolute;
  top: 26rem;
  /* right: 20px; */
  left: 20rem;
  z-index: 1;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  width: 55rem;
  background: whitesmoke;
  height: 15rem;
  overflow-y: scroll;
}

.belowNavbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #015998;
}

.firmListHeadingDiv {
  margin-left: 2rem;
  width: 60%;
}

.search-closeDiv {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 40%;
}
.navBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.startNavBar {
  text-align: center;
  width: 13%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.endNavBar {
  width: 10%;
  display: flex-end;
  justify-content: space-around;
  align-items: center;
}

.searchBar {
  border-radius: 5px;
}

.productText {
  color: whitesmoke;
}

.navBar2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navBar2Start {
  display: flex;
  width: 17%;
  justify-content: space-between;
  align-items: center;
}
.navBar2End {
  width: 20%;
  display: flex;
}
.searchBar2 {
  border-radius: 5px;
  width: 15rem;
}

.tableDiv {
  table-layout: auto;
  width: 100%;
  border: none;
  border-collapse: collapse;
}
.thDiv {
  color: whitesmoke;
  background-color: #015998;
}
 .tdDiv{
  text-align: center;
}
/*.SD{
  width: 10%;
}
.FD{
  width: 60%;
}
.AD{
  width: 15%;
} */

tr:nth-child(even) {
  background-color: #e6e6e6;
}

.myBtn {
  margin-left: 40rem;
  margin-top: 20rem;
  background-color: #015998;
  color: whitesmoke;
  border-radius: 1rem;
  border-color: whitesmoke;
  cursor: pointer;
}

.iconsDiv {
  cursor: pointer;
}
