
  .login-main-container{
    align-self: flex-end; /* align to the right */
    /* display: flex; */
    justify-content: flex-start; /* align items to the right */
    align-items: center; /* center items vertically */
    margin-left: 160px;
    /* margin-bottom: 100px; */
  }
  



.login-container {
  display: flex;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
}

.left-container {
  /* flex: 1; */
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  height: 90%;
}

.right-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right-content {
  width: 80%;
  text-align: center;
}

.right-content > p {
  color: white;
  font-weight: bold;
}

.right-content > h2 {
  color: white;
  font-weight: bold;
}

.left-content {
  width: 100%;
  text-align: center;
}

/* Your other CSS rules... */



input {
  width: 100%;
  height: 45px;
  padding: 12px;
  margin: 10px 0;
  border: 1px solid #015998;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  transition: border-color 0.3s, box-shadow 0.3s;
  margin-left: 3px;
}

input:focus {
  outline: none;
  border-color: #4EC5F6;
  box-shadow: 0 0 5px rgba(78, 197, 246, 0.5);
}

button {
  width: 100%;
  padding: 12px;
  margin: 10px 0;
  border: none;
  border-radius: 5px;
  background-color: #015998;
  color: white;
  cursor: pointer;
  transition: all 0.3s;
}

button:hover {
  background-color: #4EC5F6;
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}



.otp-container {
  justify-content: center;
  align-items: center;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .login-container {
    flex-direction: column;
  }

  .right-container {
    height: 50vh;
  }

  .left-container {
    height: 50vh;
  }
}

