/* .invoiceContainer {
  width: 80.2vw;
} */

.invoiceHeading {
  background: #015998;
  color: whitesmoke;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-left: 5%;
  margin-right: -5%;

}

.heroInvoice {
  display: flex;
  justify-content: space-around;
  margin-top: 3rem;
}

.inputLabel {
  display: flex;
  justify-content: center;
}

.buttonDiv {
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.submitButton {
  background: #015998;
  color: whitesmoke;
  border-radius: 5px;
  height: 2.5rem;
  width: 12rem;
}

.inputfields>input {
  width:200px;
}

.topheading{
  display: flex;
  justify-content: center;
}

.invoiceInnerContainer{
  width: 66%;
  margin-left: 20%;
  margin-top: 2%;
  background-color: white;
  padding: 25px;
  border-radius: 10px;
}

.invoice-container{
  max-width: 84vw;
  min-width: 79vw;
}


/* this is new css */
