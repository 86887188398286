

/* other css is coming from ledger entry create file */

/* buttonStyles.css */
#lastbutton {
  border-radius: 3px;
  width: 55px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.deleteButton {
  background-color: #e91f1f;
  color: white;
}

.updateButton {
  background-color: darkgreen;
  color: white;
}

.saveButton{
  background-color: darkgreen;
}


/* for remarks pop up show css */

.input-remarks-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.input-remarks-modal-main {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5); /* Box shadow for a pop-up effect */
}

.display-none {
  display: none;
}

