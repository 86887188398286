#table-customer-Firm-list {
  width: 100%;
  overflow-x: auto; /* Enable horizontal scrolling if needed */
}

#customers-Firm-list-table {
  width: 100%;
  border-collapse: collapse;
  /* table-layout: fixed; */
   /* Fixed table layout */
}

#customers-Firm-list-table th {
  background-color: #015998;
  color: white;
  padding: 8px;
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#firm-list-values-body td#values-firm-list-view-id {
  border: 1px solid #ddd;
  width: 150px; /* Fixed width */
  /* white-space: ra;  */
  /* Prevent wrapping */
  overflow: hidden; /* Hide overflowing content */
  text-overflow: ellipsis; /* Show ellipsis for overflow */
}

/* for pdf and csv widht */
.show-name-Productlist {
  display: flex;
  align-items: center;
  margin-left: 5%;
}

.show-name-Productlist>input {
  margin-left: 10px;
}

.show-name-Productlist>select {
  margin-left: 10px;
  padding: 6px;
  border-radius: 5px;
  border-color: #130707;
  width: 70px;
}
