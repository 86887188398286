body {
  position: absolute;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5;
}

.app {
  display: flex;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.main-div-purchase---entry {
  height: fit-content;
  /* width: 77vw; */
  background-color: white;
  /* border: 2px solid #015998; */
  overflow-x: hidden;
}

.div-header-purchase {
  height: 20px;
  color: white;
  background: #015998;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  /* width: 74vw; */
  margin-left: 5%;
  margin-right: 2%;
}

.first-half-purchase {
  display: flex;
  margin-top: 10px;
  /* width: 79vw; */
  gap: 10px;
}

.top-two-purchase {
  display: grid;
  gap: 10px;
  margin-left: 5%;
}

.input-box-purchase {
  width: 59vw;
  height: 200px;
  margin-top: 0px;
  border: 1px solid #015998;
  border-radius: 0px;
  background: #ffffff;
  display: flex;
  flex-wrap: wrap;
  /* overflow:  auto ; */
  font-weight: 700;
  font-size: large;
}

.input-box-purchase>input {
  background: #ffffff;
  border-radius: 4px;
  height: 25px;
  margin-top: 5px;
  width: 200px;
}

.input-box-name-purchase {
  margin-left: 5%;
  display: flex;
  flex-direction: column;
}

.input-box-name-purchase>select {
  width: 180px;
}

.input-box-region-purchase {
  display: flex;
  margin-left: 5%;
  flex-direction: column;
}

.input-box-region-purchase>select {
  width: 180px;
}

.input-box-date-purchase {
  margin-top: 6px;
}

.input-box-date-purchase {
  margin-left: 5%;
  display: flex;
  flex-direction: column;
  margin-top: 0.4rem;
  background-color: rgb(255, 255, 255);
  font: white;
  cursor: pointer;
  border-radius: 7px;
}

.input-box-invoice-purchase {
  margin-left: 5%;
  display: flex;
  flex-direction: column;
  margin-top: 0.4rem;
  background-color: rgb(255, 255, 255);
  font: white;
  cursor: pointer;
  border-radius: 7px;
}

.regionDiv {
  width: 20%;
  margin-left: 5%;
}

.cnRef-box-invoice-purchase {
  margin-left: 5%;
  display: flex;
  flex-direction: column;
  margin-top: 0.4rem;
  background-color: rgb(255, 255, 255);
  font: white;
  cursor: pointer;
  border-radius: 7px;
}

.quantity-box-purchase {
  width: 59vw;
  height: 320px;
  border: 1px solid #015998;
  border-radius: 0px;
  background: #ffffff;
}

.product {
  overflow: auto;
  /* height: 500px; */
}



.qty-comments-purchase-entry {
  position: absolute;
  width: 29rem;
  padding: 1rem;
  top: 516px;
  margin-right: 5rem;
  margin-left: 15rem;
  /* height: 12rem; */
  border: 5px solid #015998;
  border-radius: 10px;
  background-color: hsl(0, 0%, 100%);
}

.box>div>div>label {
  font-size: small;
  font-family: Arial, Helvetica, sans-serif;
}

.box>div>div>div>div>label {
  font-size: larger;
  font-family: Arial, Helvetica, sans-serif;
}

.label-for-purchase-second {
  align-items: center;
  display: flex;
  justify-content: left;
  gap: 1rem;
}

.label-for-second>div {
  display: grid;
}

.label-for-purchase-second>div>input {
  height: 30px;
  width: 60px;
}

.label-for-four-purchase {
  align-items: center;
  display: flex;
  justify-content: left;
  gap: 1rem;
  margin-top: 20px;
}

.label-for-four-purchase>div>input {
  height: 30px;
  width: 50px;
  margin-left: 8px;
}

.label-for-four-purchase>div {
  display: grid;
}

.TAX {
  margin-top: 1.4rem;
  font-size: larger;
  font-family: Arial, Helvetica, sans-serif;
}

#BatchNo {
  height: 30px;
  width: 90px;
}

#Expiry {
  height: 30px;
  width: 90px;
}

#MRP {
  height: 30px;
  width: 80px;
}

#HSN {
  height: 30px;
  width: 90px;
}

/* .qty-details-purchase {
  position: absolute;
  top: 55rem;
  right: 31rem;
  height: 150px;
  width: 450px;
  font-size: small;
  padding: 1px;
  overflow: auto;
} */

.qty-details-header>th {
  position: -webkit-sticky;
  position: sticky;
  height: fit-content;
  width: fit-content;
  margin-top: 10rem;
}

#customers--purchase tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers--purchase{
  /* width: fit-content; */
}

/* // firm contact box auto fill details rifht side  */

.contact-box--purchase {
  width: 200px;
  display: grid;
  justify-content: center;
  /* background: #f5f5f5; */
  border: 1px solid #015998;
  border-radius: 0px;
  padding-top: 0.6rem;
  padding-bottom: 1rem;
}

.contact-box--purchase>input {
  margin-left: 5px;
  width: 145px;
  height:30px;
  /* background: #f5f5f5; */
  border: 1px solid #000000;
  border-radius: 4px;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.2);

}

.contact-box--purchase>label {
  margin-left: 3%;
  margin-top: -0.2rem;
}

.bottom-half-purchase {
  display: flex;
  gap: 10px;
  margin-top: 5px;
  width: 77vw;
  margin-left: 5%;
  margin-right: 5%;
}

.total-box-purchase {
  width: 59vw;
  border: 1px solid #015998;
  border-radius: 0px;
  display: flex;
  justify-content: space-between;
  height: 23rem;
}

.total-empty-purchase {
  margin-top: 12px;
  width: 45vw;
  font-size: small;
  gap: 0;
  height: 240px;
   border: 1px solid #015998;
  border-radius: 1px;
  margin-left: 10px;
  padding: 10px;
  margin-bottom: 10px;
}

.save-print-view-button-purchase {
  margin-top: 18.5rem;
  margin-left: 4rem;

  display: flex;
  height: fit-content;
  width: fit-content;
  justify-content: center;
  align-items: flex-end;
}

.save-print-view-button-purchase > button{
  align-items: center;
  justify-content: center;
  display: flex;
  font-weight: bold;
}

.savebutton_purchase {
  cursor: pointer;
  background-color: #015998;
  color: white;
  font-size: large;
  height: 40px;
  width: 100px;
  /* border: 3px solid #000000; */
  border-radius: 10px;
  margin-top: -20rem;
}

.viewbutton_purchase {
  cursor: pointer;
  background-color: #015998;
  color: white;
  font-size: large;
  height: 40px;
  width: 100px;
  /* border: 3px solid #000000; */
  border-radius: 10px;
  margin-top: -20rem;
}

.printbutton_purchase {
  cursor: pointer;
  background-color: #015998;
  color: white;
  font-size: large;
  height: 40px;
  width: 100px;
  /* border: 3px solid #000000; */
  border-radius: 10px;
  margin-top: -20rem;
}

.total-values-button--purchase {
  /* display: grid; */
  /* margin-top:10px;
  margin-right: 1%;
  width: 12vw; */
  width: 150px;
  margin-top:8px;
  /* display: grid; */
  justify-content: center;
  background: #ffffff;
  /* border: 1px solid #015998; */
  border-radius: 1px;
  margin-right: 1%;
}



/* before added bootstarp */
/* .total-values-button--purchase>button {
  cursor: pointer;
  background-color: #015998;
  color: white;
  font-size: inherit;
  /* border: 2px solid #000000; */
  /* border-radius: 10px;
  height: 2.7rem;
  margin: 2px;
  font-weight: bold; */

/* } 
/* after added bootstarp */
.total-values-button--purchase>button {
  background-color: #015998;
  color: white;
  /* font-size: inherit; */
  /* border: 2px solid #000000; */
  border-radius: 10px;
  height: 30px;
  /* margin: 1px; */
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.blank-box-purchase {
  width: 163px;
  justify-content: center;
  background: #ffffff;
  border: 1px solid #015998;
  border-radius: 1px;
}

.blank-box-purchase>input {
  /* margin-left: 5px; */
  width: 150px;
  /* height: 2rem; */
  height: 30px;
  background: white;
  /* border: 5px; */
  /* border-radius: 5px; */
  border: 1px solid #000000;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.2);
  margin-left: 5px;
}

/*customers-purchase-entry table */

#customers-purchase-entry {
  border-collapse: collapse;
  width: 100%;
  overflow: auto;
}

#customers-purchase-entry td,
#customers-purchase-entry th {
  padding: 3.3px;
}

#customers-purchase-entry input {
  width: 70px;
  height: 30px;
  background-color: #36323237;
  border: 0px solid;
  text-align: center;
}

#customers-purchase-entry tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers-purchase-entry tr:hover {
  background-color: #d9d9d9;
}

#customers-purchase-entry th {
  padding-top: 1px;
  padding-bottom: 1px;
  text-align: center;
  background-color: #015998;
  color: white;
  width: 4rem;
}

#customers-purchase-entry tr>td {
  text-align: center;
  cursor: pointer;
}




.checkbox-td {
  display: flex;
}

.checkbox-1 {
  margin-left: -1rem;
}

.checkbox-3 {
  margin-right: -1rem;
  margin-left: -2rem;

}

.checkbox-2 {
  margin-left: -2rem;
}

.submit-firm {
  background-color: #015998;
  margin-left: 8rem;
  margin-top: 1rem;
  height: 30px;
  width: 150px;
  font-size: large;
  font-family: Arial, Helvetica, sans-serif;
  cursor: pointer;
  color: white;
}

.ProductName-entry {
  height: 30px;
  width: 300px;
  background-color: rgb(206, 202, 206);
  font: white;
  cursor: pointer;
  border-radius: 7px;
}

.FirmName-purchase {
  margin-top: 0.4rem;
  height: 28px;
  width: 100px;
  background-color: rgb(255, 255, 255);
  font: white;
  cursor: pointer;
  border-radius: 7px;
  font-weight: bold;
  color: black;
}

.Date-Invoice-Amt-Main {
  border: #ff1515;
  height: 10rem;
  width: 43rem;

  display: flex;
  align-items: grid;
  justify-content: center;
}

.Date-Invoice-Amt {
  position: absolute;
  width: 333px;
  height: 30px;
  background: #015998;
  border-radius: 10px;
}

.Date-Invoice-Amt-Main>div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4rem;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  font-size: larger;
  font-weight: bold;
}

.input-div {
  position: absolute;
  width: 333px;
  height: 30px;
  margin-top: 3rem;
  background: #d9d9d9;
  border-radius: 10px;
}

.input-div>input {
  background-color: #d9d9d9;
  width: 60px;
  border: none;
}

.product-result-label {
  font-family: Arial, Helvetica, sans-serif;
  color: white;
  margin: 1px;
  padding: 1px;
}

.product-result {
  margin: 1px;
  padding: 1px;
  height: 12px;
  width: 150px;
}

#arrayofobject-update {
  position: relative;
  width: 46px;
  height: 25px;
  /* margin-left: 1px;
  margin-top: -10px; */
}

.none {
  display: none;
}

.recent-history-table {
  /* margin-top: 10px; */
  position: absolute;
  top: 55rem;
  right: 37rem;
  max-width: 37rem;
  max-height: 12rem;
  overflow: auto;
}


.side-bar-details-in-purchase-entry{
  color: black;
}





/* for product select in the middle of the screen */
/* styles.css */

/* Center modal in the middle of the screen */
.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  /* top: 0; */
  left: 0;
  bottom: 230px;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px); /* Add blur effect to the background */
  z-index: 1;
}

/* Blur the background */
.blurBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(2px);
  z-index: 1; /* Ensure it's below the modal */
}

/* on click tax pop up in the center code */
.modal-background {
  position: fixed;
  /* top: 0; */
  left: 0;
  width: 100%;
  height: 100%;
  /* backdrop-filter: blur(2px); */
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 300px;
  z-index: 999; /* Ensure it's above other content */
}

/* Close button */
.close-button {
  position: absolute !important;
  top: 5px !important;
  right: 0  !important;
  background: none !important;
  border: none !important;
  cursor: pointer !important;
  font-size: 10px !important;
  color: #333 !important;
  width: 10px !important;
  height: 10px !important;
  padding: 0 !important;
  line-height: 10px !important;
  overflow: hidden !important;
 
}








/* this css for purchase view main  ke andr one product view  */




/* // modal full view details ki css for purchase view main */


/* to make backgroudn bluer and make componenet in cneter */
.modal-overlay {
  position: fixed;
  /* top: 0; */
  /* left: 0; */

  /* bottom: -10; */
  /* width: 100..%; */
  /* height: 100%; */
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
   /* ensure it's on top of other elements */
}


/* Rest of your styles remain the same */



.recent--history--table{
  margin-top: 20px;
  width: 80.5%;
}

  .allViewDetails-purchase-view{
    border: solid 5px #015998;
    border-radius: 20px;
    height: fit-content;
    width: fit-content;
    background-color:#f5f7f8;
    color: rgb(14, 7, 7);
    font-weight: bolder;
    margin-top: -46rem;
    margin-left: 15rem;
    position: relative;
    display: grid;
  }


.CloseView-purchase{
  background-color: #015998;
  color: #f8f8f8;
  height: 10;
  width: 10;
  /* margin-left: 19rem; */
}

.productnameview input {
  width: 100px; /* Set the desired width */
  height: 25px; /* Set the desired height */
}

.viewPratham{
  display: flex;
  gap: 2rem;
}

.productnameview{
  display: grid;
}

  .ViewlistFirst{
     display: flex;
     gap: 2rem;
  }
  .ViewlistSecond{
    display: flex;
    gap: 2rem;
  }
  .ViewlistThird{
     display: flex;
  }
.ViewlistFourth{
  display: flex;
  gap: 2rem;
}

.viewDivitiye{
  display: flex;
  gap: 2rem;
}
.viewtritya{
  display: flex;
  gap: 2rem;
}






